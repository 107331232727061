import { useCallback, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Text } from 'rebass'
import styled from 'styled-components/macro'

import { ReactComponent as SuccessfulImage } from '../../assets/svg/successful.svg'
import { AutoColumn, ColumnCenter } from '../../components/Column'

const PAYBOLT_API = process.env.REACT_APP_PAYBOLT_API
if (typeof PAYBOLT_API === 'undefined') {
  throw new Error(`REACT_APP_PAYBOLT_API must be a defined environment variable`)
}

const SuccesfulIcon = styled(ColumnCenter)`
  margin: 0 auto 38px;
  max-width: 121px;
  max-height: 121px;

  @media screen and (max-width: 567px) {
    max-width: 90px;
    max-height: 90px;
  }
`

const Content = styled.div`
  width: 100%;
`

const MainPayWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1023px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @media screen and (max-width: 767px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
`

const PayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 360px;
  background-color: #ffffff;
  border-radius: 14px;
  padding: 28px 40px;
  width: 100%;
`

const PayHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 4px;
`

const PayHeaderItem = styled.div`
  position: relative;

  @media screen and (max-width: 767px) {
    width: 100%;
    margin: 10px 0;
  }
`

const PayHeaderLabel = styled.div`
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: -0.02em;
  color: #10131b;
  margin-bottom: 4px;
`

const PayHeaderName = styled.div`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #10131b;
  margin-bottom: 4px;
`

const PayHeaderCountry = styled.div`
  font-size: 13px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #10131b;
  margin-bottom: 17px;
`

const PayHeaderOrder = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;

    li {
      padding: 0;
      margin: 0;
      display: flex;
    }

    span {
      flex: 1;
      font-size: 14px;
      line-height: 35px;
      font-weight: 500;
      letter-spacing: -0.02em;
      color: #10131b;

      &:first-child {
        flex: 0 0 131px;
        max-width: 131px;
        color: #8c8ca4;
      }

      &:last-child {
        text-align: right;
      }
    }
  }
`

const PayBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -2px;
  margin-right: -2px;
`

export default function Pay({
  history,
  match: {
    params: { sid },
  },
}: RouteComponentProps<{ sid: string }>) {
  const [sessionId, setSessionId] = useState('')
  const [paymentDetails, setPaymentDetails] = useState<any>()

  useEffect(() => {
    if (sid) {
      setSessionId(sid as string)
    }
  }, [sid])

  const getData = useCallback(() => {
    const PAY_API = `${PAYBOLT_API}/pay/${sessionId}`

    const fetchData = async () => {
      try {
        const resp = await fetch(PAY_API, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        })
        const data = await resp.json()
        if (data) {
          data.sessionId = sessionId
          setPaymentDetails(data)
        }
      } catch (e) {
        console.log(`Fetch PAY_API: ${e}`)
      }
    }

    fetchData()
  }, [sessionId])

  useEffect(() => {
    if (sessionId) {
      getData()
    }
  }, [sessionId, getData])

  useEffect(() => {
    if (paymentDetails) {
      if (!paymentDetails.status || paymentDetails.status !== 'captured') {
        history.push(`/pay/${sessionId}`)
      }
    }
  }, [paymentDetails, getData, history, sessionId])

  return (
    <>
      <Content>
        <MainPayWrapper>
          <PayWrapper>
            <SuccesfulIcon>
              <SuccessfulImage />
            </SuccesfulIcon>
            <AutoColumn gap="12px" justify="center">
              <Text fontSize="20px">Payment Successful</Text>
            </AutoColumn>
            <PayHeader>
              <PayHeaderItem>
                <PayHeaderLabel>Paid to</PayHeaderLabel>
                <PayHeaderName>{paymentDetails?.company_name}</PayHeaderName>
                <PayHeaderCountry>
                  {paymentDetails?.location?.name &&
                    paymentDetails?.location?.name !== paymentDetails?.country?.name && (
                      <>{paymentDetails?.location?.name}, </>
                    )}
                  {paymentDetails?.country?.name}
                </PayHeaderCountry>
              </PayHeaderItem>
            </PayHeader>
            <PayBody>
              <PayHeaderOrder>
                <ul>
                  <li>
                    <span>Reference Id:</span>
                    <span>#{paymentDetails?.merchant_reference_id}</span>
                  </li>
                  <li>
                    <span>Amount paid:</span>
                    <span>
                      {paymentDetails?.amount?.toFixed(2)} {paymentDetails?.currency}
                    </span>
                  </li>
                  <li>
                    <span>PayBolt Txn ID:</span>
                    <span>{paymentDetails?.uid}</span>
                  </li>
                </ul>
              </PayHeaderOrder>
            </PayBody>
          </PayWrapper>
        </MainPayWrapper>
      </Content>
    </>
  )
}
