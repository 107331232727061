import { Trans } from '@lingui/macro'
import { Percent } from '@uniswap/sdk-core'
import styled from 'styled-components/macro'

import { RowBetween, RowFixed } from '../Row'
import SettingsTab from './Settings'

const StyledPayHeader = styled.div`
  padding: 1rem 1.25rem 0.5rem 1.25rem;
  width: 100%;
  color: ${({ theme }) => theme.text2};
`

const TitleLabel = styled.h2`
  margin: 0 8px 0 0 !important;
`

export default function PayHeader({ allowedSlippage }: { allowedSlippage: Percent }) {
  return (
    <StyledPayHeader>
      <RowBetween>
        <RowFixed>
          <TitleLabel>
            <Trans>Secure Payment</Trans>
          </TitleLabel>
        </RowFixed>
        <RowFixed>
          <SettingsTab placeholderSlippage={allowedSlippage} />
        </RowFixed>
      </RowBetween>
    </StyledPayHeader>
  )
}
