import { Currency, Price } from '@uniswap/sdk-core'
import useUSDCPrice from 'hooks/useUSDCPrice'
import { useContext } from 'react'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components/macro'

interface TradePriceProps {
  price: Price<Currency, Currency>
  paymentDetails: any | null
}

const StyledPriceContainer = styled.button`
  background-color: transparent;
  border: none;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  grid-template-columns: 1fr auto;
  grid-gap: 0.25rem;
  display: flex;
  flex-direction: row;
  text-align: center;
  flex-wrap: wrap;
  padding: 8px 0;
  user-select: text;
`

export default function TradePrice({ price, paymentDetails }: TradePriceProps) {
  const theme = useContext(ThemeContext)

  const usdcPrice = useUSDCPrice(price.baseCurrency)

  const labelInverted = `${price.baseCurrency?.symbol} `

  const text = `${'1 ' + labelInverted + ' = ' + usdcPrice?.toSignificant(6, { groupSeparator: ',' }) ?? '-'} ${
    paymentDetails?.currency
  }`

  return (
    <StyledPriceContainer title={text}>
      <Text fontWeight={500} color={theme.text1}>
        {text}
      </Text>
    </StyledPriceContainer>
  )
}
