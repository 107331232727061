import { Trans } from '@lingui/macro'
import { Currency, Percent, TradeType } from '@uniswap/sdk-core'
import { ButtonPrimary } from 'components/Button'
import PayModalHeader from 'components/pay/PayModalHeader'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Text } from 'rebass'
import { InterfaceTrade } from 'state/routing/types'
import { WrapTransactionInfo } from 'state/transactions/actions'
import { useTransaction } from 'state/transactions/hooks'
import styled, { ThemeContext } from 'styled-components/macro'

import Circle from '../../assets/images/blue-loader.svg'
import { AutoColumn, ColumnCenter } from '../../components/Column'
import { ExternalLink } from '../../theme'
import { CustomLightSpinner } from '../../theme'
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink'

const PAYBOLT_API = process.env.REACT_APP_PAYBOLT_API
if (typeof PAYBOLT_API === 'undefined') {
  throw new Error(`REACT_APP_PAYBOLT_API must be a defined environment variable`)
}

const Content = styled.div`
  padding: 1rem 0 0.5rem 0;
  width: 100%;
  color: ${({ theme }) => theme.text2};
`

const TitleLabel = styled.h2`
  margin: 0 8px 0 0 !important;
  text-align: left;
`

const PayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 14px;
  padding: 35px 15px;
  width: 100%;
  text-align: left;
`

const PayHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 4px;
`

const PayHeaderItem = styled.div`
  position: relative;

  @media screen and (max-width: 767px) {
    width: 100%;
    margin: 10px 0;
  }
`

const PayHeaderLabel = styled.div`
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: -0.02em;
  color: #10131b;
  margin-bottom: 4px;
`

const PayHeaderName = styled.div`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #10131b;
  margin-bottom: 4px;
`

const PayHeaderCountry = styled.div`
  font-size: 13px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #10131b;
  margin-bottom: 17px;
`

const PayHeaderOrder = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;

    li {
      padding: 0;
      margin: 0;
      display: flex;
    }

    span {
      flex: 1;
      font-size: 14px;
      line-height: 35px;
      font-weight: 500;
      letter-spacing: -0.02em;
      color: #10131b;

      &:first-child {
        flex: 0 0 131px;
        max-width: 131px;
        color: #8c8ca4;
      }

      &:last-child {
        text-align: right;
      }
    }
  }
`

const PayBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -2px;
  margin-right: -2px;
`

const BottomSection = styled.div`
  text-align: center;
  margin: 40px auto 0;
`

const ConfirmedIcon = styled(ColumnCenter)<{ inline?: boolean }>`
  padding: ${({ inline }) => (inline ? '20px 0' : '32px 0;')};
`

export default function Submitted({
  txHash,
  trade,
  allowedSlippage,
  paymentDetails,
}: {
  txHash: string
  trade?: InterfaceTrade<Currency, Currency, TradeType> | undefined
  allowedSlippage: Percent
  paymentDetails?: any
}) {
  const theme = useContext(ThemeContext)

  const [payboltTxnId, setPayboltTxnId] = useState('')
  const [chainId, setChainId] = useState<number>()

  const tx = useTransaction(txHash)

  const goToMerchantPageNow = () => {
    window.location.href = paymentDetails.success_url
      ? paymentDetails.success_url
      : paymentDetails.website
      ? paymentDetails.website
      : ''
  }

  useEffect(() => {
    const goToMerchantPage = () => {
      window.location.href = paymentDetails.success_url
        ? paymentDetails.success_url
        : paymentDetails.website
        ? paymentDetails.website
        : ''
    }

    const postComplete = async () => {
      try {
        const txnChainId = (tx?.info as WrapTransactionInfo)?.chainId
        if (txnChainId) {
          setChainId(txnChainId)
        }

        const resp = await fetch(PAYBOLT_API + '/pay/' + paymentDetails.sessionId + '/complete', {
          method: 'POST',
          //headers: { "Content-Type": "application/json" }
          //headers: {'Content-Type':'application/x-www-form-urlencoded'},
          body: JSON.stringify({ txn_id: txHash, chain_id: txnChainId }),
        })
        const data = await resp.json()

        //console.log(data)

        if (data.status) {
          setPayboltTxnId(data.paybolt_reference_id)
          setTimeout(function () {
            goToMerchantPage()
          }, 10 * 1000)
        } else {
          alert(data.message)
        }
      } catch (e) {
        console.log(`Error PAYBOLT_API: ${e}`)
      }
    }

    if (tx) {
      const success = Boolean(tx.receipt && tx.receipt.status === 1)

      if (success) {
        postComplete()
      }
    }
  }, [tx, txHash, paymentDetails])

  const ModalHeader = useCallback(() => {
    return trade ? (
      <PayModalHeader
        trade={trade}
        allowedSlippage={allowedSlippage}
        recipient={null}
        showAcceptChanges={false}
        onAcceptChanges={() => false}
        paymentDetails={paymentDetails}
      />
    ) : null
  }, [paymentDetails, trade, allowedSlippage])

  return !payboltTxnId ? (
    <>
      <Content>
        <TitleLabel>
          <Trans>Secure Payment</Trans>
        </TitleLabel>
        <PayWrapper>
          <ModalHeader />
          <ConfirmedIcon inline={false}>
            <CustomLightSpinner src={Circle} alt="loader" size="90px" />
          </ConfirmedIcon>
          <AutoColumn gap="12px" justify="center">
            <Text fontSize="20px">Waiting For Confirmation</Text>
          </AutoColumn>
        </PayWrapper>
      </Content>
    </>
  ) : (
    <>
      <Content>
        <TitleLabel>
          <Trans>Payment Receipt</Trans>
        </TitleLabel>
        <PayWrapper>
          <PayHeader>
            <PayHeaderItem>
              <PayHeaderLabel>Paid to</PayHeaderLabel>
              <PayHeaderName>{paymentDetails?.company_name}</PayHeaderName>
              <PayHeaderCountry>
                {paymentDetails?.location?.name && paymentDetails?.location?.name !== paymentDetails?.country?.name && (
                  <>{paymentDetails?.location?.name}, </>
                )}
                {paymentDetails?.country?.name}
              </PayHeaderCountry>
            </PayHeaderItem>
          </PayHeader>
          <PayBody>
            <PayHeaderOrder>
              <ul>
                <li>
                  <span>Reference Id:</span>
                  <span>#{paymentDetails?.merchant_reference_id}</span>
                </li>
                <li>
                  <span>Amount paid:</span>
                  <span>
                    {paymentDetails?.amount?.toFixed(2)} {paymentDetails?.currency}
                  </span>
                </li>
                <li>
                  <span>PayBolt Txn ID:</span>
                  <span>{payboltTxnId}</span>
                </li>
              </ul>
            </PayHeaderOrder>
            {chainId && txHash && (
              <ExternalLink href={getExplorerLink(chainId, txHash, ExplorerDataType.TRANSACTION)}>
                <Text fontWeight={500} fontSize={14} color={theme.primary1}>
                  <Trans>View on Explorer</Trans>
                </Text>
              </ExternalLink>
            )}
            <BottomSection>
              <ButtonPrimary onClick={goToMerchantPageNow}>
                <Trans>Go back to merchant page now</Trans>
              </ButtonPrimary>
            </BottomSection>
            <BottomSection>
              <Text fontSize="14px">Redirecting you back to merchant page in 10 secs..</Text>
            </BottomSection>
          </PayBody>
        </PayWrapper>
      </Content>
    </>
  )
}
