// eslint-disable-next-line no-restricted-imports
import { BigNumber } from '@ethersproject/bignumber'
import { TransactionResponse } from '@ethersproject/providers'
import { Trans } from '@lingui/macro'
import { Percent } from '@uniswap/sdk-core'
import { FeeOptions } from '@uniswap/v3-sdk'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useENS from 'hooks/useENS'
import { SignatureData } from 'hooks/useERC20Permit'
import { AnyTrade, usePayCallArguments } from 'hooks/usePayCallArguments'
import { ReactNode, useMemo } from 'react'

import useSendPayTransaction from './useSendPayTransaction'

export enum PayCallbackState {
  INVALID,
  LOADING,
  VALID,
}

interface UsePayCallbackReturns {
  state: PayCallbackState
  callback?: () => Promise<TransactionResponse>
  error?: ReactNode
}
interface UsePayCallbackArgs {
  trade: AnyTrade | undefined // trade to execute, required
  allowedSlippage: Percent // in bips
  recipientAddressOrName: string | null | undefined // the ENS name or address of the recipient of the trade, or null if pay should be returned to sender
  signatureData: SignatureData | null | undefined
  deadline: BigNumber | undefined
  feeOptions?: FeeOptions
  merchantId: string
  merchantReferenceId: string
}

// returns a function that will execute a pay, if the parameters are all valid
// and the user has approved the slippage adjusted input amount for the trade
export function usePayCallback({
  trade,
  allowedSlippage,
  recipientAddressOrName,
  signatureData,
  deadline,
  feeOptions,
  merchantId,
  merchantReferenceId,
}: UsePayCallbackArgs): UsePayCallbackReturns {
  const { account, chainId, library } = useActiveWeb3React()

  const payCalls = usePayCallArguments(
    trade,
    allowedSlippage,
    recipientAddressOrName,
    signatureData,
    deadline,
    feeOptions,
    merchantId,
    merchantReferenceId
  )
  const { callback } = useSendPayTransaction(account, chainId, library, trade, payCalls)

  const { address: recipientAddress } = useENS(recipientAddressOrName)
  const recipient = recipientAddressOrName === null ? account : recipientAddress

  return useMemo(() => {
    if (!trade || !library || !account || !chainId || !callback) {
      return { state: PayCallbackState.INVALID, error: <Trans>Missing dependencies</Trans> }
    }
    if (!recipient) {
      if (recipientAddressOrName !== null) {
        return { state: PayCallbackState.INVALID, error: <Trans>Invalid recipient</Trans> }
      } else {
        return { state: PayCallbackState.LOADING }
      }
    }

    return {
      state: PayCallbackState.VALID,
      callback: async () => callback(),
    }
  }, [trade, library, account, chainId, callback, recipient, recipientAddressOrName])
}
