import { Trans } from '@lingui/macro'
import { Trade } from '@uniswap/router-sdk'
import { Currency, Token, TradeType } from '@uniswap/sdk-core'
import { Trade as V2Trade } from '@uniswap/v2-sdk'
import { Trade as V3Trade } from '@uniswap/v3-sdk'
import PayDetailsDropdown from 'components/pay/PayDetailsDropdown'
import UnsupportedCurrencyFooter from 'components/pay/UnsupportedCurrencyFooter'
import { MouseoverTooltip } from 'components/Tooltip'
import { History } from 'history'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useDebounce from 'hooks/useDebounce'
import { usePayCallback } from 'hooks/usePayCallback'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import JSBI from 'jsbi'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ArrowDown, CheckCircle, HelpCircle } from 'react-feather'
import ReactGA from 'react-ga4'
import { Text } from 'rebass'
import { TradeState } from 'state/routing/types'
import styled from 'styled-components/macro'
import { ThemeContext } from 'styled-components/macro'

import Secure from '../../assets/images/secured.png'
import AddressInputPanel from '../../components/AddressInputPanel'
import { ButtonConfirmed, ButtonError, ButtonLight, ButtonPrimary } from '../../components/Button'
import { GreyCard } from '../../components/Card'
import { AutoColumn } from '../../components/Column'
import CurrencyLogo from '../../components/CurrencyLogo'
import Loader from '../../components/Loader'
import ConfirmPayModal from '../../components/pay/ConfirmPayModal'
import confirmPriceImpactWithoutFee from '../../components/pay/confirmPriceImpactWithoutFee'
import CurrencyInputPanel from '../../components/pay/CurrencyInputPanel'
import CurrencyOutputPanel from '../../components/pay/CurrencyOutputPanel'
import PayHeader from '../../components/pay/PayHeader'
import { ArrowWrapper, PayCallbackError, Wrapper } from '../../components/pay/styleds'
import { AutoRow } from '../../components/Row'
import { SwitchLocaleLink } from '../../components/SwitchLocaleLink'
import TokenWarningModal from '../../components/TokenWarningModal'
import { PAYBOLT_ADDRESS, USD_ADDRESS } from '../../constants/addresses'
import { PAYBOLT_PAY, TOKEN_SHORTHANDS } from '../../constants/tokens'
import { useAllTokens, useCurrency } from '../../hooks/Tokens'
import useENSAddress from '../../hooks/useENSAddress'
import { useERC20PermitFromTrade, UseERC20PermitState } from '../../hooks/useERC20Permit'
import useIsArgentWallet from '../../hooks/useIsArgentWallet'
import { useIsSwapUnsupported } from '../../hooks/useIsSwapUnsupported'
import {
  ApprovalState,
  useApprovalOptimizedTrade,
  useApproveCallbackFromTrade,
} from '../../hooks/usePayApproveCallback'
import { useTokenRequiredForUSDCAmount, useUSDCValue } from '../../hooks/useUSDCPrice'
import useWrapCallback, { WrapErrorText, WrapType } from '../../hooks/useWrapCallback'
import { useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/pay/actions'
import { useDefaultsFromURLSearch, useDerivedPayInfo, usePayActionHandlers, usePayState } from '../../state/pay/hooks'
import { useExpertModeManager } from '../../state/user/hooks'
import { LinkStyledButton, ThemedText } from '../../theme'
import { computeFiatValuePriceImpact } from '../../utils/computeFiatValuePriceImpact'
import { warningSeverity } from '../../utils/prices'
import { supportedChainId } from '../../utils/supportedChainId'
import AppBody from '../AppBody'
import Submitted from './Submitted'

const SecureMark = styled.div`
  color: #000;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0 20px;
`

const SecureImage = styled.img`
  width: 24px;
  height: 24px;
`

export default function PaymentSection({ history, paymentDetails }: { history: History; paymentDetails?: any }) {
  const { account, chainId } = useActiveWeb3React()
  const loadedUrlParams = useDefaultsFromURLSearch()

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.[Field.INPUT]?.currencyId),
    useCurrency(loadedUrlParams?.[Field.OUTPUT]?.currencyId),
  ]
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c?.isToken ?? false) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  // dismiss warning if all imported tokens are in active lists
  const defaultTokens = useAllTokens()
  const importTokensNotInDefault = useMemo(
    () =>
      urlLoadedTokens &&
      urlLoadedTokens
        .filter((token: Token) => {
          return !Boolean(token.address in defaultTokens)
        })
        .filter((token: Token) => {
          // Any token addresses that are loaded from the shorthands map do not need to show the import URL
          const supported = supportedChainId(chainId)
          if (!supported) return true
          return !Object.keys(TOKEN_SHORTHANDS).some((shorthand) => {
            const shorthandTokenAddress = TOKEN_SHORTHANDS[shorthand][supported]
            return shorthandTokenAddress && shorthandTokenAddress === token.address
          })
        }),
    [chainId, defaultTokens, urlLoadedTokens]
  )

  const theme = useContext(ThemeContext)

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // for expert mode
  const [isExpertMode] = useExpertModeManager()

  // pay state
  const { independentField, typedValue, recipient } = usePayState()
  const {
    trade: { state: tradeState, trade },
    allowedSlippage,
    parsedAmount,
    currencies,
    currencyIds,
    inputError: payInputError,
  } = useDerivedPayInfo()

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const { address: recipientAddress } = useENSAddress(recipient)

  const parsedAmounts = useMemo(
    () =>
      showWrap
        ? {
            [Field.INPUT]: parsedAmount,
            [Field.OUTPUT]: parsedAmount,
          }
        : {
            [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
            [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
          },
    [independentField, parsedAmount, showWrap, trade]
  )

  const [routeNotFound, routeIsLoading, routeIsSyncing] = useMemo(
    () => [!trade?.swaps, TradeState.LOADING === tradeState, TradeState.SYNCING === tradeState],
    [trade, tradeState]
  )

  const fiatValueInput = useUSDCValue(trade?.inputAmount)
  const fiatValueOutput = useUSDCValue(trade?.outputAmount)

  // total PAY required for the transaction
  const payTokenRequired =
    useTokenRequiredForUSDCAmount(PAYBOLT_PAY[chainId ? chainId : 1], paymentDetails?.usd_amount?.toString()) ?? null
  const debouncedPayTokenRequired = useDebounce(payTokenRequired, 5000)

  const priceImpact = useMemo(
    () => (routeIsSyncing ? undefined : computeFiatValuePriceImpact(fiatValueInput, fiatValueOutput)),
    [fiatValueInput, fiatValueOutput, routeIsSyncing]
  )

  const { onCurrencySelection, onUserInput, onChangeRecipient, onSetCurrencyId } = usePayActionHandlers()
  const isValid = !payInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  // only run once during first load
  useEffect(() => {
    if (!currencies[Field.OUTPUT] && chainId && paymentDetails && paymentDetails.usd_amount) {
      if (paymentDetails.status === 'captured') {
        history.push(`/success/${paymentDetails.sessionId}`)
      }

      onSetCurrencyId(Field.OUTPUT, USD_ADDRESS[chainId])
      onUserInput(Field.OUTPUT, paymentDetails.usd_amount.toString())
    }
  }, [currencies, paymentDetails, chainId, onSetCurrencyId, onUserInput, history])

  // update target PAY token needed, only when target output is PAY token
  useEffect(() => {
    if (chainId && debouncedPayTokenRequired && currencyIds[Field.OUTPUT] === PAYBOLT_ADDRESS[chainId]) {
      // update every 5 seconds debouncedPayTokenRequired.toFixed(2)
      onUserInput(Field.OUTPUT, debouncedPayTokenRequired.toFixed(2))
    }
  }, [debouncedPayTokenRequired, chainId, currencyIds, onUserInput, paymentDetails])

  // reset if they close warning without tokens in params
  const handleDismissTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
    history.push('/pay/')
  }, [history])

  // modal and loading
  const [{ showConfirm, tradeToConfirm, payErrorMessage, attemptingTxn, txHash }, setPayState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade<Currency, Currency, TradeType> | undefined
    attemptingTxn: boolean
    payErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    payErrorMessage: undefined,
    txHash: undefined,
  })

  const formattedAmounts = useMemo(
    () => ({
      [independentField]: typedValue,
      [dependentField]: showWrap
        ? parsedAmounts[independentField]?.toExact() ?? ''
        : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
    }),
    [dependentField, independentField, parsedAmounts, showWrap, typedValue]
  )

  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )

  const approvalOptimizedTrade = useApprovalOptimizedTrade(trade, allowedSlippage)
  const approvalOptimizedTradeString =
    approvalOptimizedTrade instanceof V2Trade
      ? 'V2SwapRouter'
      : approvalOptimizedTrade instanceof V3Trade
      ? 'V3SwapRouter'
      : 'SwapRouter'

  // check whether the user has approved the router on the input token
  const [approvalState, approveCallback] = useApproveCallbackFromTrade(approvalOptimizedTrade, allowedSlippage)
  const transactionDeadline = useTransactionDeadline()
  const {
    state: signatureState,
    signatureData,
    gatherPermitSignature,
  } = useERC20PermitFromTrade(approvalOptimizedTrade, allowedSlippage, transactionDeadline)

  const handleApprove = useCallback(async () => {
    if (signatureState === UseERC20PermitState.NOT_SIGNED && gatherPermitSignature) {
      try {
        await gatherPermitSignature()
      } catch (error) {
        // try to approve if gatherPermitSignature failed for any reason other than the user rejecting it
        if (error?.code !== 4001) {
          await approveCallback()
        }
      }
    } else {
      await approveCallback()

      ReactGA.event({
        category: 'Pay',
        action: 'Approve',
        label: [approvalOptimizedTradeString, approvalOptimizedTrade?.inputAmount?.currency.symbol].join('/'),
      })
    }
  }, [
    signatureState,
    gatherPermitSignature,
    approveCallback,
    approvalOptimizedTradeString,
    approvalOptimizedTrade?.inputAmount?.currency.symbol,
  ])

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approvalState === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approvalState, approvalSubmitted])

  // the callback to execute the pay
  const { callback: payCallback, error: payCallbackError } = usePayCallback(
    approvalOptimizedTrade,
    allowedSlippage,
    chainId ? paymentDetails?.merchant_contract_address_multichain[chainId] : '',
    signatureData,
    paymentDetails
  )

  const handlePay = useCallback(() => {
    if (!payCallback) {
      return
    }
    if (priceImpact && !confirmPriceImpactWithoutFee(priceImpact)) {
      return
    }
    setPayState({ attemptingTxn: true, tradeToConfirm, showConfirm, payErrorMessage: undefined, txHash: undefined })
    payCallback()
      .then((hash) => {
        setPayState({ attemptingTxn: false, tradeToConfirm, showConfirm, payErrorMessage: undefined, txHash: hash })
        ReactGA.event({
          category: 'Pay',
          action:
            recipient === null
              ? 'Pay w/o Send'
              : (recipientAddress ?? recipient) === account
              ? 'Pay w/o Send + recipient'
              : 'Pay w/ Send',
          label: [
            approvalOptimizedTradeString,
            approvalOptimizedTrade?.inputAmount?.currency?.symbol,
            approvalOptimizedTrade?.outputAmount?.currency?.symbol,
            'MH',
          ].join('/'),
        })
      })
      .catch((error) => {
        setPayState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          payErrorMessage: error.message,
          txHash: undefined,
        })
      })
  }, [
    payCallback,
    priceImpact,
    tradeToConfirm,
    showConfirm,
    recipient,
    recipientAddress,
    account,
    approvalOptimizedTradeString,
    approvalOptimizedTrade?.inputAmount?.currency?.symbol,
    approvalOptimizedTrade?.outputAmount?.currency?.symbol,
  ])

  // warnings on the greater of fiat value price impact and execution price impact
  const priceImpactSeverity = useMemo(() => {
    const executionPriceImpact = trade?.priceImpact
    return warningSeverity(
      executionPriceImpact && priceImpact
        ? executionPriceImpact.greaterThan(priceImpact)
          ? executionPriceImpact
          : priceImpact
        : executionPriceImpact ?? priceImpact
    )
  }, [priceImpact, trade])

  const isArgentWallet = useIsArgentWallet()

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !isArgentWallet &&
    !payInputError &&
    (approvalState === ApprovalState.NOT_APPROVED ||
      approvalState === ApprovalState.PENDING ||
      (approvalSubmitted && approvalState === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const handleConfirmDismiss = useCallback(() => {
    // two special cases, do not close the modal:
    // 1. waiting for payment confirmation -> txHash && !attemptingTxn
    // 2. payment is completed -> txHash && !isSubmissionPending
    //if ((txHash && !isSubmissionPending) || (txHash && !attemptingTxn)) {
    //} else {
    setPayState({ showConfirm: false, tradeToConfirm, attemptingTxn, payErrorMessage, txHash })
    //}
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, payErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setPayState({ tradeToConfirm: trade, payErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, payErrorMessage, trade, txHash])

  const handleInputSelect = useCallback(
    (inputCurrency) => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency)

      if (chainId) {
        // PAY -> BUSD; BNB -> BUSD; other token -> PAY
        if (inputCurrency?.address === PAYBOLT_ADDRESS[chainId] || inputCurrency.isNative) {
          // set to usd
          onSetCurrencyId(Field.OUTPUT, USD_ADDRESS[chainId])
          onUserInput(Field.OUTPUT, paymentDetails.usd_amount.toString())
        } else {
          // set to paybolt
          onSetCurrencyId(Field.OUTPUT, PAYBOLT_ADDRESS[chainId])
          if (payTokenRequired) {
            onUserInput(Field.OUTPUT, payTokenRequired.toFixed(2))
          }
        }
      }
    },
    [onCurrencySelection, chainId, onSetCurrencyId, onUserInput, paymentDetails, payTokenRequired]
  )

  const handleOutputSelect = useCallback(
    (outputCurrency) => onCurrencySelection(Field.OUTPUT, outputCurrency),
    [onCurrencySelection]
  )

  const swapIsUnsupported = useIsSwapUnsupported(currencies[Field.INPUT], currencies[Field.OUTPUT])

  const priceImpactTooHigh = priceImpactSeverity > 3 && !isExpertMode

  //console.log('trade', trade)
  //console.log('routeIsSyncing', routeIsSyncing)
  //console.log('routeIsLoading', routeIsLoading)
  //console.log('priceImpactTooHigh', priceImpactTooHigh)
  //console.log('currencies[Field.INPUT]', currencies[Field.INPUT])

  return txHash ? (
    <Submitted txHash={txHash} trade={trade} allowedSlippage={allowedSlippage} paymentDetails={paymentDetails} />
  ) : (
    <>
      <TokenWarningModal
        isOpen={importTokensNotInDefault.length > 0 && !dismissTokenWarning}
        tokens={importTokensNotInDefault}
        onConfirm={handleConfirmTokenWarning}
        onDismiss={handleDismissTokenWarning}
      />
      <AppBody>
        <PayHeader allowedSlippage={allowedSlippage} />
        <Wrapper id="pay-page">
          <ConfirmPayModal
            isOpen={showConfirm}
            trade={trade}
            originalTrade={tradeToConfirm}
            onAcceptChanges={handleAcceptChanges}
            attemptingTxn={attemptingTxn}
            txHash={txHash}
            recipient={recipient}
            allowedSlippage={allowedSlippage}
            onConfirm={handlePay}
            payErrorMessage={payErrorMessage}
            onDismiss={handleConfirmDismiss}
            sid={paymentDetails?.sessionId}
            paymentDetails={paymentDetails}
          />

          <AutoColumn gap={'sm'}>
            <div style={{ display: 'relative' }}>
              <CurrencyInputPanel
                label={<Trans>Pay with</Trans>}
                value={formattedAmounts[Field.INPUT]}
                showMaxButton={false}
                currency={currencies[Field.INPUT]}
                onUserInput={() => true}
                onMax={() => true}
                fiatValue={fiatValueInput ?? undefined}
                onCurrencySelect={handleInputSelect}
                otherCurrency={currencies[Field.OUTPUT]}
                showCommonBases={true}
                id="pay-currency-input"
                loading={independentField === Field.OUTPUT && routeIsSyncing}
              />
              <ArrowWrapper clickable={false}>
                <ArrowDown size="16" color="#bbb" />
              </ArrowWrapper>
              {false && (
                <CurrencyInputPanel
                  value={formattedAmounts[Field.OUTPUT]}
                  onUserInput={() => true}
                  label={
                    independentField === Field.INPUT && !showWrap ? <Trans>To (at least)</Trans> : <Trans>To</Trans>
                  }
                  showMaxButton={false}
                  hideBalance={false}
                  fiatValue={fiatValueOutput ?? undefined}
                  priceImpact={priceImpact}
                  currency={currencies[Field.OUTPUT]}
                  onCurrencySelect={handleOutputSelect}
                  otherCurrency={currencies[Field.INPUT]}
                  showCommonBases={true}
                  id="pay-currency-output"
                  loading={independentField === Field.INPUT && routeIsSyncing}
                />
              )}
              <CurrencyOutputPanel
                id="pay-currency-output"
                value={paymentDetails?.amount?.toFixed(2)}
                currency={paymentDetails?.currency}
              />
            </div>

            {recipient !== null && !showWrap ? (
              <>
                <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                  <ArrowWrapper clickable={false}>
                    <ArrowDown size="16" color={theme.text2} />
                  </ArrowWrapper>
                  <LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                    <Trans>- Remove recipient</Trans>
                  </LinkStyledButton>
                </AutoRow>
                <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
              </>
            ) : null}
            {!showWrap && userHasSpecifiedInputOutput && (trade || routeIsLoading || routeIsSyncing) && (
              <PayDetailsDropdown
                trade={trade}
                syncing={routeIsSyncing}
                loading={routeIsLoading}
                allowedSlippage={allowedSlippage}
                paymentDetails={paymentDetails}
              />
            )}
            <div style={{ marginTop: 10 }}>
              {swapIsUnsupported ? (
                <ButtonPrimary disabled={true}>
                  <ThemedText.Main mb="4px">
                    <Trans>Unsupported Asset</Trans>
                  </ThemedText.Main>
                </ButtonPrimary>
              ) : !account ? (
                <ButtonLight onClick={toggleWalletModal}>
                  <Trans>Connect Wallet</Trans>
                </ButtonLight>
              ) : showWrap ? (
                <ButtonPrimary disabled={Boolean(wrapInputError)} onClick={onWrap}>
                  {wrapInputError ? (
                    <WrapErrorText wrapInputError={wrapInputError} />
                  ) : wrapType === WrapType.WRAP ? (
                    <Trans>Wrap</Trans>
                  ) : wrapType === WrapType.UNWRAP ? (
                    <Trans>Unwrap</Trans>
                  ) : null}
                </ButtonPrimary>
              ) : routeNotFound && userHasSpecifiedInputOutput && !routeIsLoading && !routeIsSyncing ? (
                <GreyCard style={{ textAlign: 'center' }}>
                  <ThemedText.Main mb="4px">
                    <Trans>Insufficient liquidity for this trade.</Trans>
                  </ThemedText.Main>
                </GreyCard>
              ) : showApproveFlow ? (
                <AutoRow style={{ flexWrap: 'nowrap', width: '100%' }}>
                  <AutoColumn style={{ width: '100%' }} gap="12px">
                    <ButtonConfirmed
                      onClick={handleApprove}
                      disabled={
                        approvalState !== ApprovalState.NOT_APPROVED ||
                        approvalSubmitted ||
                        signatureState === UseERC20PermitState.SIGNED
                      }
                      width="100%"
                      altDisabledStyle={approvalState === ApprovalState.PENDING} // show solid button while waiting
                      confirmed={
                        approvalState === ApprovalState.APPROVED || signatureState === UseERC20PermitState.SIGNED
                      }
                    >
                      <AutoRow justify="space-between" style={{ flexWrap: 'nowrap' }}>
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          <CurrencyLogo
                            currency={currencies[Field.INPUT]}
                            size={'20px'}
                            style={{ marginRight: '8px', flexShrink: 0 }}
                          />
                          {/* we need to shorten this string on mobile */}
                          {approvalState === ApprovalState.APPROVED || signatureState === UseERC20PermitState.SIGNED ? (
                            <Trans>You can now pay with {currencies[Field.INPUT]?.symbol}</Trans>
                          ) : (
                            <Trans>Allow the PayBolt to access your {currencies[Field.INPUT]?.symbol}</Trans>
                          )}
                        </span>
                        {approvalState === ApprovalState.PENDING ? (
                          <Loader stroke="white" />
                        ) : (approvalSubmitted && approvalState === ApprovalState.APPROVED) ||
                          signatureState === UseERC20PermitState.SIGNED ? (
                          <CheckCircle size="20" color={theme.green1} />
                        ) : (
                          <MouseoverTooltip
                            text={
                              <Trans>
                                You must give the PayBolt smart contracts permission to access your{' '}
                                {currencies[Field.INPUT]?.symbol}.
                              </Trans>
                            }
                          >
                            <HelpCircle size="20" color={'white'} style={{ marginLeft: '8px' }} />
                          </MouseoverTooltip>
                        )}
                      </AutoRow>
                    </ButtonConfirmed>
                    <ButtonError
                      onClick={() => {
                        if (isExpertMode) {
                          handlePay()
                        } else {
                          setPayState({
                            tradeToConfirm: trade,
                            attemptingTxn: false,
                            payErrorMessage: undefined,
                            showConfirm: true,
                            txHash: undefined,
                          })
                        }
                      }}
                      width="100%"
                      id="pay-button"
                      disabled={
                        !isValid ||
                        routeIsSyncing ||
                        routeIsLoading ||
                        (approvalState !== ApprovalState.APPROVED && signatureState !== UseERC20PermitState.SIGNED) ||
                        priceImpactTooHigh
                      }
                      error={isValid && priceImpactSeverity > 2}
                    >
                      <Text fontSize={16} fontWeight={500}>
                        {priceImpactTooHigh ? (
                          <Trans>High Price Impact</Trans>
                        ) : trade && priceImpactSeverity > 2 ? (
                          <Trans>Pay Anyway</Trans>
                        ) : (
                          <Trans>Pay</Trans>
                        )}
                      </Text>
                    </ButtonError>
                  </AutoColumn>
                </AutoRow>
              ) : (
                <ButtonError
                  onClick={() => {
                    if (isExpertMode) {
                      handlePay()
                    } else {
                      setPayState({
                        tradeToConfirm: trade,
                        attemptingTxn: false,
                        payErrorMessage: undefined,
                        showConfirm: true,
                        txHash: undefined,
                      })
                    }
                  }}
                  id="pay-button"
                  disabled={!isValid || routeIsSyncing || routeIsLoading || priceImpactTooHigh || !!payCallbackError}
                  error={isValid && priceImpactSeverity > 2 && !payCallbackError}
                >
                  <Text fontSize={20} fontWeight={500}>
                    {payInputError ? (
                      payInputError
                    ) : routeIsSyncing || routeIsLoading ? (
                      <Trans>Pay</Trans>
                    ) : priceImpactSeverity > 2 ? (
                      <Trans>Pay Anyway</Trans>
                    ) : priceImpactTooHigh ? (
                      <Trans>Price Impact Too High</Trans>
                    ) : (
                      <Trans>Pay</Trans>
                    )}
                  </Text>
                </ButtonError>
              )}
              {payErrorMessage ? <PayCallbackError error={payErrorMessage} /> : null}
            </div>
          </AutoColumn>
          <SecureMark>
            <SecureImage src={Secure} alt="Secure Payment" />
            &nbsp;Secure Payment
          </SecureMark>
        </Wrapper>
      </AppBody>
      <SwitchLocaleLink />
      {!swapIsUnsupported ? null : (
        <UnsupportedCurrencyFooter
          show={swapIsUnsupported}
          currencies={[currencies[Field.INPUT], currencies[Field.OUTPUT]]}
        />
      )}
    </>
  )
}
