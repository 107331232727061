import { BigNumber } from '@ethersproject/bignumber'
import { RouteV2, Trade } from '@uniswap/router-sdk'
import { Currency, CurrencyAmount, Percent, Token, TradeType, validateAndParseAddress } from '@uniswap/sdk-core'
import { Trade as V2Trade } from '@uniswap/v2-sdk'
import { FeeOptions, Trade as V3Trade } from '@uniswap/v3-sdk'
import PayBoltABI from 'abis/paybolt.json'
import PayBoltExtensionABI from 'abis/payboltExtension.json'
import PayBoltMerchantABI from 'abis/payboltMerchant.json'
import { PAYBOLT_ADDRESS, PAYBOLT_EXTENSION_ADDRESS, PAYBOLT_MERCHANT_ADDRESS } from 'constants/addresses'
import { ZERO_PERCENT } from 'constants/misc'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useMemo } from 'react'

//import { InterfaceTrade } from 'state/routing/types'
import { asciiToHex, getContract } from '../utils'
import useENS from './useENS'
import { SignatureData } from './useERC20Permit'

export type AnyTrade =
  | V2Trade<Currency, Currency, TradeType>
  | V3Trade<Currency, Currency, TradeType>
  | Trade<Currency, Currency, TradeType>

interface PayParameters {
  /**
   * The method to call on the Pancake Router.
   */
  methodName: string
  /**
   * The arguments to pass to the method, all hex encoded.
   */
  args: (string | string[])[]
  /**
   * The amount of wei to send in hex.
   */
  value: string
}

interface PayCall {
  address: string
  calldata: string
  value: string
}

function toHex(currencyAmount: CurrencyAmount<Currency>) {
  return `0x${currencyAmount.quotient.toString(16)}`
}

const ZERO_HEX = '0x0'

const getPaddedMerchantIdHex = (id: number) => {
  const merchantIdHex = Number(id).toString(16).padStart(8, '0')
  return `0x${merchantIdHex}`
}

const getPaddedTxnRefHex = (refId: string) => {
  return asciiToHex(refId, 32)
}

/*
import { BIG_INT_ONE, BIG_INT_ZERO } from 'constants/misc'
import invariant from 'tiny-invariant'
const maximumAmountIn = (
  trade: AnyTrade,
  slippageTolerance: Percent,
  isPayBoltToken: boolean
): CurrencyAmount<Currency> => {
  invariant(!slippageTolerance.lessThan(BIG_INT_ZERO), 'SLIPPAGE_TOLERANCE')
  if (trade.tradeType === TradeType.EXACT_INPUT) {
    return trade.inputAmount
  } else {
    let slippageAdjustedAmountIn = new Fraction(BIG_INT_ONE)
      .add(slippageTolerance)
      .multiply(trade.inputAmount.quotient).quotient

    if (isPayBoltToken) {
      slippageAdjustedAmountIn =
        slippageAdjustedAmountIn - (slippageAdjustedAmountIn % JSBI.BigInt('10000000000000000'))
    }

    return CurrencyAmount.fromRawAmount(trade.inputAmount.currency, slippageAdjustedAmountIn)
  }
}
*/

/**
 * Prepare 2 types of methods call for every chain
 *  - PAY tokens
 *  - every other tokens other than PAY
 */
export function payCallParameters(
  trade: AnyTrade,
  options: any,
  chainId: number,
  sourceTokenAddress: string
): PayParameters {
  const payboltIn = sourceTokenAddress === PAYBOLT_ADDRESS[chainId]
  const to: string = validateAndParseAddress(options.recipient)

  // const amountIn: string = toHex(trade.maximumAmountIn(options.allowedSlippage)) //toHex(maximumAmountIn(trade, options.allowedSlippage, payboltIn))

  const merchantIdHex = getPaddedMerchantIdHex(options.merchantId)
  const refIdHex = getPaddedTxnRefHex(options.merchantReferenceId)
  const data = merchantIdHex + refIdHex.replace('0x', '')

  let methodName: string
  let args: (string | string[])[]
  let value: string

  if (payboltIn) {
    const amountIn: string = toHex(trade.maximumAmountIn(ZERO_PERCENT))
    methodName = 'paySecurelyWithPaybolt(address,uint256,bytes)'
    // (address to, uint256 value, bytes memory data)
    args = [to, amountIn, data]
    value = ZERO_HEX
  } else {
    const amountIn: string = toHex(trade.maximumAmountIn(options.allowedSlippage))
    methodName = 'paySecurelyWithAnyToken(address,address,uint256,uint256,bytes)'
    // (address tokenIn, address to, uint256 value, uint256 minValue, bytes memory data)
    args = [sourceTokenAddress, to, amountIn, amountIn, data]
    value = ZERO_HEX
  }

  return {
    methodName,
    args,
    value,
  }
}

/**
 * Prepare 2 types of methods call for every chain
 *  - PAY tokens
 *  - every other tokens other than PAY
 */
export function payExtCallParameters(
  trade: AnyTrade,
  options: any,
  chainId: number,
  sourceTokenAddress: string
): PayParameters {
  //const payboltIn = sourceTokenAddress === PAYBOLT_ADDRESS[chainId]

  const to: string = validateAndParseAddress(options.recipient)

  const amountIn: string = toHex(trade.maximumAmountIn(options.allowedSlippage)) //toHex(maximumAmountIn(trade, options.allowedSlippage, payboltIn))
  const amountOut: string = toHex(trade.minimumAmountOut(options.allowedSlippage))

  //  console.log("amountIn", maximumAmountIn(trade, options.allowedSlippage, payboltIn).toSignificant(6))
  //  console.log("amountOut", trade.minimumAmountOut(options.allowedSlippage).toSignificant(6))

  const path: string[] = options.path // trade.route.path.map((token) => token.address)
  //  console.log("path", path)
  const deadline =
    'ttl' in options
      ? `0x${(Math.floor(new Date().getTime() / 1000) + options.ttl).toString(16)}`
      : `0x${options.deadline.toString(16)}`

  const merchantIdHex = getPaddedMerchantIdHex(options.merchantId)
  const refIdHex = getPaddedTxnRefHex(options.merchantReferenceId)
  const data = merchantIdHex + refIdHex.replace('0x', '')

  const methodName = 'paySecurelyWithAnyToken'
  const args: (string | string[])[] = [sourceTokenAddress, amountIn, amountOut, path, to, deadline, data]
  const value: string = ZERO_HEX

  return {
    methodName,
    args,
    value,
  }
}

/**
 * For native token/coin payment only
 *  - Pay in BNB / ETH / MATIC
 */
export function merchantContractCallParameters(trade: AnyTrade, options: any, chainId: number): PayParameters {
  //const amountIn: string = toHex(trade.maximumAmountIn(options.allowedSlippage))
  const amountIn: string = toHex(trade.maximumAmountIn(ZERO_PERCENT))

  const merchantIdHex = getPaddedMerchantIdHex(options.merchantId)
  const refIdHex = getPaddedTxnRefHex(options.merchantReferenceId)
  const data = merchantIdHex + refIdHex.replace('0x', '')

  const methodName = 'payInNative'
  const args: (string | string[])[] = [data]
  const value: string = amountIn

  return {
    methodName,
    args,
    value,
  }
}

/**
 * Returns the pay calls that can be used to make the trade
 * @param trade trade to execute
 * @param allowedSlippage user allowed slippage
 * @param recipientAddressOrName the ENS name or address of the recipient of the pay output
 * @param signatureData the signature data of the permit of the input token amount, if available
 */
export function usePayCallArguments(
  trade: AnyTrade | undefined,
  allowedSlippage: Percent,
  recipientAddressOrName: string | null | undefined,
  signatureData: SignatureData | null | undefined,
  deadline: BigNumber | undefined,
  feeOptions: FeeOptions | undefined,
  merchantId: string, // merchant id
  merchantReferenceId: string // merchant contract address
): PayCall[] {
  const { account, chainId, library } = useActiveWeb3React()

  const { address: recipientAddress } = useENS(recipientAddressOrName)
  const recipient = recipientAddressOrName === null ? account : recipientAddress

  return useMemo(() => {
    if (!trade || !recipient || !library || !account || !chainId || !deadline || !merchantId || !merchantReferenceId)
      return []

    //console.log('------------usePayCallArguments', trade)

    let payPath: string[] = []

    const etherIn = trade.inputAmount.currency.isNative

    // extract path only if not paying use native coin
    if (trade instanceof V3Trade) {
      // not supported
      return []
    } else if (trade instanceof V2Trade) {
      payPath = trade.route.path.map((token: Token) => token.address)
    } else {
      for (const { route } of trade.swaps) {
        payPath = (route as RouteV2<Currency, Currency>).path.map((token: Token) => token.address)
      }
    }

    const sourceTokenAddress = payPath.length > 0 ? payPath[0] : ''

    const useExtendedPayBoltContract = sourceTokenAddress !== PAYBOLT_ADDRESS[chainId]

    const contract = etherIn
      ? getContract(PAYBOLT_MERCHANT_ADDRESS[chainId], PayBoltMerchantABI, library, account)
      : useExtendedPayBoltContract
      ? getContract(PAYBOLT_EXTENSION_ADDRESS[chainId], PayBoltExtensionABI, library, account)
      : getContract(PAYBOLT_ADDRESS[chainId], PayBoltABI, library, account)

    if (!contract) {
      return []
    }

    if (!contract) return []
    const payMethods = []

    payMethods.push(
      etherIn
        ? merchantContractCallParameters(
            trade,
            {
              allowedSlippage,
              recipient,
              merchantId,
              merchantReferenceId,
            },
            chainId
          )
        : useExtendedPayBoltContract
        ? payExtCallParameters(
            trade,
            {
              allowedSlippage,
              recipient,
              merchantId,
              merchantReferenceId,
              deadline: deadline.toNumber(),
              path: payPath,
            },
            chainId,
            sourceTokenAddress
          )
        : payCallParameters(
            trade,
            {
              allowedSlippage,
              recipient,
              merchantId,
              merchantReferenceId,
            },
            chainId,
            sourceTokenAddress
          )
    )

    //console.log('payMethods', payMethods, contract)
    return payMethods.map(({ methodName, args, value }) => {
      return {
        address: contract.address,
        calldata: contract.interface.encodeFunctionData(methodName, args),
        value,
      }
    })
  }, [account, allowedSlippage, chainId, deadline, library, recipient, trade, merchantId, merchantReferenceId])
}
