import { Trans } from '@lingui/macro'
import { Currency, Percent, TradeType } from '@uniswap/sdk-core'
import { OutlineCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import { LoadingOpacityContainer } from 'components/Loader/styled'
import Row, { RowBetween, RowFixed } from 'components/Row'
import { PAYBOLT_ADDRESS } from 'constants/addresses'
import { SUPPORTED_GAS_ESTIMATE_CHAIN_IDS } from 'constants/chains'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { InterfaceTrade } from 'state/routing/types'
import styled, { keyframes } from 'styled-components/macro'
import { ThemedText } from 'theme'

import { getInputCurrencyAddress } from '../../utils'
import { AdvancedPayDetails } from './AdvancedPayDetails'
import GasEstimateBadge from './GasEstimateBadge'
//import PayRoute from './PayRoute'
import TradePrice from './TradePrice'

const Wrapper = styled(Row)`
  width: 100%;
  justify-content: center;
`

const StyledCard = styled(OutlineCard)`
  padding: 12px;
  border: 1px solid ${({ theme }) => theme.bg2};
`

const StyledHeaderRow = styled(RowBetween)<{ disabled: boolean }>`
  padding: 4px 8px;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  min-height: 40px;
`

const StyledPolling = styled.div`
  display: flex;
  height: 16px;
  width: 16px;
  margin-right: 2px;
  margin-left: 10px;
  align-items: center;
  color: ${({ theme }) => theme.text1};
  transition: 250ms ease color;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `}
`

const StyledPollingDot = styled.div`
  width: 8px;
  height: 8px;
  min-height: 8px;
  min-width: 8px;
  border-radius: 50%;
  position: relative;
  background-color: ${({ theme }) => theme.bg2};
  transition: 250ms ease background-color;
`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  animation: ${rotate360} 1s cubic-bezier(0.83, 0, 0.17, 1) infinite;
  transform: translateZ(0);
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-left: 2px solid ${({ theme }) => theme.text1};
  background: transparent;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: relative;
  transition: 250ms ease border-color;
  left: -3px;
  top: -3px;
`

interface PayDetailsInlineProps {
  trade: InterfaceTrade<Currency, Currency, TradeType> | undefined
  syncing: boolean
  loading: boolean
  allowedSlippage: Percent
  paymentDetails: any | null
}

export default function PayDetailsDropdown({
  trade,
  syncing,
  loading,
  allowedSlippage,
  paymentDetails,
}: PayDetailsInlineProps) {
  const { chainId } = useActiveWeb3React()

  const sourceTokenAddress = trade ? getInputCurrencyAddress(trade) : ''
  const showSlippage =
    trade && chainId && sourceTokenAddress !== PAYBOLT_ADDRESS[chainId] && !trade.inputAmount.currency.isNative

  return (
    <Wrapper>
      <AutoColumn gap={'8px'} style={{ width: '100%', marginBottom: '-8px' }}>
        <StyledHeaderRow disabled={!trade} open={true}>
          <RowFixed style={{ position: 'relative' }}>
            {loading || syncing ? (
              <StyledPolling>
                <StyledPollingDot>
                  <Spinner />
                </StyledPollingDot>
              </StyledPolling>
            ) : null}
            {trade ? (
              <LoadingOpacityContainer $loading={syncing}>
                <TradePrice price={trade.executionPrice} paymentDetails={paymentDetails} />
              </LoadingOpacityContainer>
            ) : loading || syncing ? (
              <ThemedText.Main fontSize={14}>
                <Trans>Fetching best price...</Trans>
              </ThemedText.Main>
            ) : null}
          </RowFixed>
          <RowFixed>
            {!trade?.gasUseEstimateUSD || !chainId || !SUPPORTED_GAS_ESTIMATE_CHAIN_IDS.includes(chainId) ? null : (
              <GasEstimateBadge trade={trade} loading={syncing || loading} showRoute={false} disableHover={true} />
            )}
          </RowFixed>
        </StyledHeaderRow>
        {showSlippage ? (
          <StyledCard>
            <AdvancedPayDetails trade={trade} allowedSlippage={allowedSlippage} syncing={syncing} />
          </StyledCard>
        ) : null}
        {/*trade ? <PayRoute trade={trade} syncing={syncing} /> : null*/}
      </AutoColumn>
    </Wrapper>
  )
}
