import useParsedQueryString from 'hooks/useParsedQueryString'
import { useState } from 'react'
import styled from 'styled-components/macro'

import ErrorImage from '../assets/images/error.png'
import Secure from '../assets/images/secured.png'

const SecureMark = styled.div`
  color: #000;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 20px 20px;
`

const SecureImage = styled.img`
  width: 24px;
  height: 24px;
`
const ErrorImageWrapper = styled.img`
  width: 100px;
  height: 100px;
`

const Container = styled.div`
  padding: 2rem 0 2rem;

  h2 {
    color: #000;
    padding: 20px 0 40px;
  }

  .ac {
    text-align: center;
  }

  p {
    margin-top: 0;
  }

  .text-center {
    text-align: center;
  }

  .actionButtons {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }

  .button {
    min-width: 150px;
    padding: 1rem 1.5rem;
    background: '#2562ff';
    border: none;
    border-radius: 0.5rem;
    color: #fff;
    font-size: 1.2rem;
    cursor: pointer;
  }

  .payToLabel {
    margin-bottom: 5px;
    margin-top: 20px;
  }

  .mb-0 {
    margin-bottom: 0;
  }
  .mb-1 {
    margin-bottom: 0.25rem;
  }

  .container .row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .align-items-center {
    align-items: center !important;
  }
  .justify-content-center {
    justify-content: center !important;
  }
  .h-100 {
    height: 100% !important;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .card {
    margin-left: 20px;
    margin-right: 20px;
    border: 1px solid rgba(55, 55, 89, 0.1);
    margin-bottom: 30px;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgb(55 55 89 / 8%);
    background: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
  }

  .card .card-body {
    color: #000;
    min-width: 320px;
    padding: 30px;
    background: #fff;
    border-radius: 15px;
    flex: 1 1 auto;
  }

  .card .card-body p {
    line-height: 1.5;
  }

  .card h3 {
    font-size: 26px;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    color: #000;
  }

  .card .page-back {
    display: inline-block;
    margin-bottom: 15px;
    text-decoration: none;
    outline: none;
    --bs-text-opacity: 1;
    color: #000 !important;
  }

  .card .mb-3 {
    margin-bottom: 30px;
    position: relative;
    margin-bottom: 1rem !important;
  }

  .card .mb-3 label {
    font-size: 18px;
    font-weight: 500;
    color: #000;
    margin-bottom: 15px;
    background: transparent;
    border-color: rgba(55, 55, 89, 0.1);
    display: inline-block;
  }
`

export const ErrorPage = (): JSX.Element => {
  const [goBackUrl, setGoBackUrl] = useState('')

  const qs = useParsedQueryString()

  if (qs.returnUrl) {
    setGoBackUrl(qs.returnUrl as string)
  }

  const handleGoBack = () => {
    window.location.href = goBackUrl
  }

  return (
    <>
      <Container>
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-xl-5 col-md-6">
            <div className="auth-form card">
              <div className="card-body ac">
                <h2 className="text-center">Payment Error</h2>

                <div className="">
                  <ErrorImageWrapper src={ErrorImage} alt="Error" />
                </div>
                <br />
                <br />

                {qs.err ? (
                  <>
                    <p>{qs.err}</p>
                    <br />
                    <p>
                      Please consider to contact support from original site <br />
                      or return to your original site and restart your activity.
                    </p>
                  </>
                ) : (
                  <>
                    <p>Sorry, your session has been timed out or cannot be continued. </p>
                    <br />
                    <p>Please return to your original site and restart your activity.</p>
                  </>
                )}

                <br />
                <br />
                <br />
                {goBackUrl && (
                  <div className="ac">
                    <button className="button" type="button" style={{ backgroundColor: '#ccc' }} onClick={handleGoBack}>
                      Back To Merchant
                    </button>
                  </div>
                )}
                <br />
              </div>
              <SecureMark>
                <SecureImage src={Secure} alt="Secure Payment" />
                &nbsp;Secure Payment
              </SecureMark>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default ErrorPage
