import styled from 'styled-components/macro'

import AppStoreIcon from '../../assets/images/app-store-icon.svg'
import AppStoreComingSoon from '../../assets/images/app-store-soon.svg'
import GooglePlayIcon from '../../assets/images/google-play-icon.svg'
import GooglePlayComingSoon from '../../assets/images/google-play-soon.svg'
import WalletPreviewImage from '../../assets/images/wallet-preview.jpg'

const PayBodyQrCode = styled.div`
  display: flex;
  algin-items: center;
  justify-content: center;
  height: 300px;
  margin: 55px auto 46px;

  @media screen and (max-width: 567px) {
    width: 150px;
    height: 150px;
    margin: 30px auto;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
`

const PayBodyStore = styled.div`
  display: flex;
  justify-content: center;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    margin-left: -10px;
    margin-right: -10px;

    @media screen and (max-width: 567px) {
      margin-left: -5px;
      margin-right: -5px;
    }

    li {
      padding: 0 10px 10px;
      margin: 0;

      @media screen and (max-width: 567px) {
        padding: 0 5px 10px;
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      //padding: 10px;
      width: 140px;
      height: 50px;
      //border: 1px solid #10131b;
      border-radius: 10px;
      transition: all 0.4s ease;

      @media screen and (max-width: 567px) {
        width: 100%;
        height: 40px;
      }

      &:hover {
        border-color: rgba(16, 19, 27, 0.5);
      }
    }
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
`

export default function WalletTeaser() {
  return true ? (
    <>
      <h2>
        <strong>PayBolt Wallet</strong> coming soon
      </h2>

      <PayBodyQrCode>
        <img src={WalletPreviewImage} alt="Wallet Preview" />
      </PayBodyQrCode>

      <p>
        Faster and Secure way to pay
        <br /> Coming soon!
      </p>

      <PayBodyStore>
        <ul>
          <li>
            <img src={AppStoreComingSoon} alt="appstore" />
          </li>
          <li>
            <img src={GooglePlayComingSoon} alt="googleplay" />
          </li>
        </ul>
      </PayBodyStore>
    </>
  ) : (
    <>
      <h2>
        Scan with <strong>PayBolt Wallet</strong> to pay
      </h2>

      <PayBodyQrCode>
        <img src={WalletPreviewImage} alt="" />
      </PayBodyQrCode>

      <p>
        Don’t have Paybolt Wallet?
        <br /> Get it now!
      </p>

      <PayBodyStore>
        <ul>
          <li>
            <a href="/pay" title="App Store">
              <img src={AppStoreIcon} alt="appstore" />
            </a>
          </li>
          <li>
            <a href="/pay" title="Google Play">
              <img src={GooglePlayIcon} alt="googleplay" />
            </a>
          </li>
        </ul>
      </PayBodyStore>
    </>
  )
}
