import { Currency, CurrencyAmount, sortedInsert, Token, TradeType } from '@uniswap/sdk-core'
import { Pair, Route, Trade } from '@uniswap/v2-sdk'
import { useMemo } from 'react'
import invariant from 'tiny-invariant'
import { isTradeBetter } from 'utils/isTradeBetter'

import { BETTER_TRADE_LESS_HOPS_THRESHOLD, BIG_INT_ZERO } from '../constants/misc'
import { useAllCurrencyCombinations } from './useAllCurrencyCombinations'
import { PairState, useV2Pairs } from './useV2Pairs'

function useAllCommonPairs(currencyA?: Currency, currencyB?: Currency): Pair[] {
  const allCurrencyCombinations = useAllCurrencyCombinations(currencyA, currencyB)

  const allPairs = useV2Pairs(allCurrencyCombinations)

  return useMemo(
    () =>
      Object.values(
        allPairs
          // filter out invalid pairs
          .filter((result): result is [PairState.EXISTS, Pair] => Boolean(result[0] === PairState.EXISTS && result[1]))
          .map(([, pair]) => pair)
      ),
    [allPairs]
  )
}

const MAX_HOPS = 3

/**
 * Returns the best v2 trade for a desired swap
 * @param tradeType whether the swap is an exact in/out
 * @param amountSpecified the exact amount to swap in/out
 * @param otherCurrency the desired output/payment currency
 */
export function useBestV2Trade(
  tradeType: TradeType.EXACT_INPUT | TradeType.EXACT_OUTPUT,
  amountSpecified?: CurrencyAmount<Currency>,
  otherCurrency?: Currency,
  { maxHops = MAX_HOPS } = {}
): Trade<Currency, Currency, TradeType.EXACT_INPUT | TradeType.EXACT_OUTPUT> | null {
  const [currencyIn, currencyOut] = useMemo(
    () =>
      tradeType === TradeType.EXACT_INPUT
        ? [amountSpecified?.currency, otherCurrency]
        : [otherCurrency, amountSpecified?.currency],
    [tradeType, amountSpecified, otherCurrency]
  )
  const allowedPairs = useAllCommonPairs(currencyIn, currencyOut)

  return useMemo(() => {
    if (amountSpecified && currencyIn && currencyOut && allowedPairs.length > 0) {
      if (maxHops === 1) {
        const options = { maxHops: 1, maxNumResults: 1 }
        if (tradeType === TradeType.EXACT_INPUT) {
          const amountIn = amountSpecified
          return Trade.bestTradeExactIn(allowedPairs, amountIn, currencyOut, options)[0] ?? null
        } else {
          const amountOut = amountSpecified
          return Trade.bestTradeExactOut(allowedPairs, currencyIn, amountOut, options)[0] ?? null
        }
      }

      // search through trades with varying hops, find best trade out of them
      let bestTradeSoFar: Trade<Currency, Currency, TradeType.EXACT_INPUT | TradeType.EXACT_OUTPUT> | null = null
      for (let i = 1; i <= maxHops; i++) {
        const options = { maxHops: i, maxNumResults: 1 }
        let currentTrade: Trade<Currency, Currency, TradeType.EXACT_INPUT | TradeType.EXACT_OUTPUT> | null

        if (tradeType === TradeType.EXACT_INPUT) {
          const amountIn = amountSpecified
          currentTrade = Trade.bestTradeExactIn(allowedPairs, amountIn, currencyOut, options)[0] ?? null
        } else {
          const amountOut = amountSpecified
          currentTrade = Trade.bestTradeExactOut(allowedPairs, currencyIn, amountOut, options)[0] ?? null

          /*
          if (currencyIn.symbol === 'RISE' && currencyOut.symbol === 'BUSD') {
            currentTrade = Trade.bestTradeExactOut(true, allowedPairs, currencyIn, amountOut, options)[0] ?? null
          } else {
            currentTrade = Trade.bestTradeExactOut(false, allowedPairs, currencyIn, amountOut, options)[0] ?? null
          }
          */
        }
        // if current trade is best yet, save it
        if (isTradeBetter(bestTradeSoFar, currentTrade, BETTER_TRADE_LESS_HOPS_THRESHOLD)) {
          bestTradeSoFar = currentTrade
        }
      }
      return bestTradeSoFar
    }

    return null
  }, [tradeType, amountSpecified, currencyIn, currencyOut, allowedPairs, maxHops])
}

export interface BestTradeOptions {
  // how many results to return
  maxNumResults?: number
  // the maximum number of hops a trade should contain
  maxHops?: number
}

// minimal interface so the input output comparator may be shared across types
interface InputOutput<TInput extends Currency, TOutput extends Currency> {
  readonly inputAmount: CurrencyAmount<TInput>
  readonly outputAmount: CurrencyAmount<TOutput>
}

// comparator function that allows sorting trades by their output amounts, in decreasing order, and then input amounts
// in increasing order. i.e. the best trades have the most outputs for the least inputs and are sorted first
export function inputOutputComparator<TInput extends Currency, TOutput extends Currency>(
  a: InputOutput<TInput, TOutput>,
  b: InputOutput<TInput, TOutput>
): number {
  // must have same input and output token for comparison
  invariant(a.inputAmount.currency.equals(b.inputAmount.currency), 'INPUT_CURRENCY')
  invariant(a.outputAmount.currency.equals(b.outputAmount.currency), 'OUTPUT_CURRENCY')
  if (a.outputAmount.equalTo(b.outputAmount)) {
    if (a.inputAmount.equalTo(b.inputAmount)) {
      return 0
    }
    // trade A requires less input than trade B, so A should come first
    if (a.inputAmount.lessThan(b.inputAmount)) {
      return -1
    } else {
      return 1
    }
  } else {
    // tradeA has less output than trade B, so should come second
    if (a.outputAmount.lessThan(b.outputAmount)) {
      return 1
    } else {
      return -1
    }
  }
}

// extension of the input output comparator that also considers other dimensions of the trade in ranking them
export function tradeComparator<TInput extends Currency, TOutput extends Currency, TTradeType extends TradeType>(
  a: Trade<TInput, TOutput, TTradeType>,
  b: Trade<TInput, TOutput, TTradeType>
) {
  const ioComp = inputOutputComparator(a, b)
  if (ioComp !== 0) {
    return ioComp
  }

  // consider lowest slippage next, since these are less likely to fail
  if (a.priceImpact.lessThan(b.priceImpact)) {
    return -1
  } else if (a.priceImpact.greaterThan(b.priceImpact)) {
    return 1
  }

  // finally consider the number of hops since each hop costs gas
  return a.route.path.length - b.route.path.length
}

export function bestTradeExactOut<TInput extends Currency, TOutput extends Currency>(
  printOrNot: boolean,
  pairs: Pair[],
  currencyIn: TInput,
  currencyAmountOut: CurrencyAmount<TOutput>,
  { maxNumResults = 3, maxHops = 3 }: BestTradeOptions = {},
  // used in recursion.
  currentPairs: Pair[] = [],
  nextAmountOut: CurrencyAmount<Currency> = currencyAmountOut,
  bestTrades: Trade<TInput, TOutput, TradeType.EXACT_OUTPUT>[] = []
): Trade<TInput, TOutput, TradeType.EXACT_OUTPUT>[] {
  invariant(pairs.length > 0, 'PAIRS')
  invariant(maxHops > 0, 'MAX_HOPS')
  invariant(currencyAmountOut === nextAmountOut || currentPairs.length > 0, 'INVALID_RECURSION')

  const amountOut = nextAmountOut.wrapped
  const tokenIn = currencyIn.wrapped
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i]
    // pair irrelevant
    if (!pair.token0.equals(amountOut.currency) && !pair.token1.equals(amountOut.currency)) continue
    if (pair.reserve0.equalTo(BIG_INT_ZERO) || pair.reserve1.equalTo(BIG_INT_ZERO)) continue

    let amountIn: CurrencyAmount<Token>
    try {
      ;[amountIn] = pair.getInputAmount(amountOut)
    } catch (error) {
      // not enough liquidity in this pair
      if (error.isInsufficientReservesError) {
        continue
      }
      throw error
    }

    // we have arrived at the input token, so this is the first trade of one of the paths
    if (amountIn.currency.equals(tokenIn)) {
      if (printOrNot) {
        console.log('amountIn.currency.equals(tokenIn)', amountIn.currency, tokenIn)
      }

      sortedInsert(
        bestTrades,
        // @ts-ignore
        new Trade(
          new Route([pair, ...currentPairs], currencyIn, currencyAmountOut.currency),
          currencyAmountOut,
          TradeType.EXACT_OUTPUT
        ),
        maxNumResults,
        tradeComparator
      )
    } else if (maxHops > 1 && pairs.length > 1) {
      if (printOrNot) {
        console.log('maxHops > 1 && pairs.length > 1', amountIn.currency, tokenIn)
      }
      const pairsExcludingThisPair = pairs.slice(0, i).concat(pairs.slice(i + 1, pairs.length))

      // otherwise, consider all the other paths that arrive at this token as long as we have not exceeded maxHops
      bestTradeExactOut(
        printOrNot,
        pairsExcludingThisPair,
        currencyIn,
        currencyAmountOut,
        {
          maxNumResults,
          maxHops: maxHops - 1,
        },
        [pair, ...currentPairs],
        amountIn,
        bestTrades
      )
    } else {
      if (printOrNot) {
        console.log('else', amountIn.currency, tokenIn)
      }
    }
  }

  if (printOrNot) {
    console.log('bestTrades', bestTrades)
  }
  return bestTrades
}
