import { useCallback, useEffect, useRef, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components/macro'

import PaymentSection from './PaymentSection'
import WalletTeaser from './WalletTeaser'

const PAYBOLT_API = process.env.REACT_APP_PAYBOLT_API
if (typeof PAYBOLT_API === 'undefined') {
  throw new Error(`REACT_APP_PAYBOLT_API must be a defined environment variable`)
}

const Content = styled.div`
  width: 100%;
`

const MainPaydWrapper = styled.div`
  padding: 0 15px 0;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1023px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @media screen and (max-width: 767px) {
    padding: 15px 10px 30px;
  }
`

const PayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 918px;
  width: 100%;
`

const PayHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
  background-color: #ffffff;
  border-radius: 14px;
  padding: 28px 48px;

  @media screen and (max-width: 767px) {
    padding: 20px 30px;
    align-items: baseline;
    flex-direction: column;
  }

  @media screen and (max-width: 567px) {
    padding: 10px 25px;
  }
`

const PayHeaderItem = styled.div`
  position: relative;

  @media screen and (max-width: 767px) {
    width: 100%;
    margin: 10px 0;
  }
`

const PayHeaderLabel = styled.div`
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: -0.02em;
  color: #10131b;
  margin-bottom: 4px;
`

const PayHeaderName = styled.div`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #10131b;
  margin-bottom: 4px;
`

const PayHeaderCountry = styled.div`
  font-size: 13px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #10131b;
  margin-bottom: 17px;
`

const PayHeaderOrder = styled.div`
  display: flex;
  flex-wrap: wrap;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 233px;

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    li {
      padding: 0;
      margin: 0;
      display: flex;
    }

    span {
      flex: 1;
      font-size: 14px;
      line-height: 35px;
      font-weight: 500;
      letter-spacing: -0.02em;
      color: #10131b;

      &:first-child {
        flex: 0 0 131px;
        max-width: 131px;
        color: #8c8ca4;
      }

      &:last-child {
        text-align: right;
      }
    }
  }
`

const PayBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -2px;
  margin-right: -2px;
`

const PayBodyItem = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 2px;
  padding-right: 2px;
  margin-bottom: 4px;

  @media screen and (max-width: 1023px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const PayBodyArticle = styled.div`
  padding: 40px 35px 10px;
  background-color: #ffffff;
  text-align: center;
  height: 100%;

  @media screen and (max-width: 767px) {
    padding: 30px 20px;
  }

  @media screen and (max-width: 567px) {
    padding: 10px 10px 0;
  }

  &.first {
    border-radius: 14px 0 0 14px;

    @media screen and (max-width: 767px) {
      display: none;
    }

    @media screen and (max-width: 1023px) {
      border-radius: 14px 14px 0 0;
    }
  }

  &.second {
    border-radius: 0 14px 14px 0;

    @media screen and (max-width: 1023px) {
      border-radius: 0 0 14px 14px;
    }
  }

  h2 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: -0.02em;
    color: #10131b;
    margin-bottom: 40px;
  }

  p {
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    letter-spacing: -0.02em;
    color: #10131b;
    margin-bottom: 24px;
  }
`

export default function Pay({
  history,
  match: {
    params: { sid },
  },
}: RouteComponentProps<{ sid: string }>) {
  const reloadDataTimer = useRef<any>()
  const [sessionId, setSessionId] = useState('')
  const [paymentDetails, setPaymentDetails] = useState<any>()

  useEffect(() => {
    if (sid) {
      setSessionId(sid as string)
    }
  }, [sid])

  const getData = useCallback(() => {
    const PAY_API = `${PAYBOLT_API}/pay/${sessionId}`

    const fetchData = async () => {
      try {
        const resp = await fetch(PAY_API, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        })
        const data = await resp.json()
        if (data) {
          data.sessionId = sessionId
          setPaymentDetails(data)
        }
      } catch (e) {
        console.log(`Fetch PAY_API: ${e}`)
      }
    }

    fetchData()
  }, [sessionId])

  useEffect(() => {
    if (sessionId) {
      getData()
    }
  }, [sessionId, getData])

  useEffect(() => {
    if (paymentDetails) {
      if (!paymentDetails.status || paymentDetails.status === '' || paymentDetails.status === 'cancel') {
        if (paymentDetails.cancel_url) {
          history.push('/error?returnUrl=' + encodeURIComponent(paymentDetails.cancel_url))
        } else {
          history.push('/error')
        }
      }
      reloadDataTimer.current = setInterval(() => {
        getData()
      }, 1000 * 10) // in milliseconds
    }

    return () => {
      clearInterval(reloadDataTimer.current)
    }
  }, [paymentDetails, getData, history, sessionId])

  return (
    <>
      <Content>
        <MainPaydWrapper>
          <PayWrapper>
            <PayHeader>
              <PayHeaderItem>
                <PayHeaderLabel>Pay to</PayHeaderLabel>
                <PayHeaderName>{paymentDetails?.company_name}</PayHeaderName>
                <PayHeaderCountry>
                  {paymentDetails?.location?.name &&
                    paymentDetails?.location?.name !== paymentDetails?.country?.name && (
                      <>{paymentDetails?.location?.name}, </>
                    )}
                  {paymentDetails?.country?.name}
                </PayHeaderCountry>
              </PayHeaderItem>
              <PayHeaderItem>
                <PayHeaderOrder>
                  <ul>
                    {paymentDetails?.merchant_reference_id && (
                      <li>
                        <span>Reference Id:</span>
                        <span>#{paymentDetails?.merchant_reference_id}</span>
                      </li>
                    )}
                    <li>
                      <span>Amount Due:</span>
                      <span>
                        {paymentDetails?.currency} {paymentDetails?.amount?.toFixed(2)}
                      </span>
                    </li>
                  </ul>
                </PayHeaderOrder>
              </PayHeaderItem>
            </PayHeader>
            <PayBody>
              <PayBodyItem>
                <PayBodyArticle className="first">
                  <WalletTeaser />
                </PayBodyArticle>
              </PayBodyItem>
              <PayBodyItem>
                <PayBodyArticle className="second">
                  {paymentDetails && paymentDetails.status && (
                    <PaymentSection history={history} paymentDetails={paymentDetails} />
                  )}
                </PayBodyArticle>
              </PayBodyItem>
            </PayBody>
          </PayWrapper>
        </MainPaydWrapper>
      </Content>
    </>
  )
}
