import { Trans } from '@lingui/macro'
import { Trade } from '@uniswap/router-sdk'
import { Currency, Percent, TradeType } from '@uniswap/sdk-core'
import { ReactNode, useCallback, useMemo } from 'react'
import { InterfaceTrade } from 'state/routing/types'
import { tradeMeaningfullyDiffers } from 'utils/tradeMeaningFullyDiffer'

import PayModalFooter from './PayModalFooter'
import PayModalHeader from './PayModalHeader'
import TransactionConfirmationModal, {
  ConfirmationModalContent,
  TransactionErrorContent,
} from './TransactionConfirmationModal'

export default function ConfirmPayModal({
  trade,
  originalTrade,
  onAcceptChanges,
  allowedSlippage,
  onConfirm,
  onDismiss,
  recipient,
  payErrorMessage,
  isOpen,
  attemptingTxn,
  txHash,
  sid,
  paymentDetails,
}: {
  isOpen: boolean
  trade: InterfaceTrade<Currency, Currency, TradeType> | undefined
  originalTrade: Trade<Currency, Currency, TradeType> | undefined
  attemptingTxn: boolean
  txHash: string | undefined
  recipient: string | null
  allowedSlippage: Percent
  onAcceptChanges: () => void
  onConfirm: () => void
  payErrorMessage: ReactNode | undefined
  onDismiss: () => void
  sid: string
  paymentDetails: any | null
}) {
  const showAcceptChanges = useMemo(
    () => Boolean(trade && originalTrade && tradeMeaningfullyDiffers(trade, originalTrade)),
    [originalTrade, trade]
  )

  const modalHeader = useCallback(() => {
    return trade ? (
      <PayModalHeader
        trade={trade}
        allowedSlippage={allowedSlippage}
        recipient={recipient}
        showAcceptChanges={showAcceptChanges}
        onAcceptChanges={onAcceptChanges}
        paymentDetails={paymentDetails}
      />
    ) : null
  }, [paymentDetails, allowedSlippage, onAcceptChanges, recipient, showAcceptChanges, trade])

  const modalBottom = useCallback(() => {
    return trade ? (
      <PayModalFooter
        onConfirm={onConfirm}
        trade={trade}
        disabledConfirm={showAcceptChanges}
        payErrorMessage={payErrorMessage}
      />
    ) : null
  }, [onConfirm, showAcceptChanges, payErrorMessage, trade])

  // text to show while loading
  const pendingText = (
    <Trans>
      Paying {trade?.inputAmount?.toSignificant(6)} {trade?.inputAmount?.currency?.symbol} for{' '}
      {paymentDetails?.amount?.toFixed(2)} {paymentDetails?.currency}
    </Trans>
  )

  const confirmationContent = useCallback(
    () =>
      payErrorMessage ? (
        <TransactionErrorContent onDismiss={onDismiss} message={payErrorMessage} />
      ) : (
        <ConfirmationModalContent
          title={<Trans>Confirm Pay</Trans>}
          onDismiss={onDismiss}
          topContent={modalHeader}
          bottomContent={modalBottom}
        />
      ),
    [onDismiss, modalBottom, modalHeader, payErrorMessage]
  )

  //console.log('payErrorMessage', payErrorMessage)
  return (
    <TransactionConfirmationModal
      isOpen={isOpen}
      onDismiss={onDismiss}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      content={confirmationContent}
      pendingText={pendingText}
      currencyToAdd={trade?.outputAmount.currency}
    />
  )
}
