import styled from 'styled-components/macro'

import AppStoreComing from '../assets/images/app-store-soon.svg'
import Border from '../assets/images/border.svg'
import GooglePlayComing from '../assets/images/google-play-soon.svg'
import WalletPhoneBg from '../assets/images/wallet-phone.png'

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`

const PayBoltTitle = styled.div`
  margin-top: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 60px;
  /* identical to box height */
  text-align: center;
  letter-spacing: -0.02em;
  color: black;

  @media screen and (max-width: 850px) {
    font-size: 42px;
  }

  @media screen and (max-width: 650px) {
    font-size: 36px;
  }
`

const PayBoltSubtitle = styled.div`
  width: 700px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 34px;
  /* or 170% */
  text-align: center;
  letter-spacing: -0.02em;
  color: black;
  margin: auto;
  margin-top: 24px;

  @media screen and (max-width: 650px) {
    font-size: 18px;
    color: #6b7cab;
  }

  @media screen and (max-width: 1100px) {
    width: 100%;
  }
`

const PayBoltImage = styled.div`
  height: 750px;
  width: auto;
  position: relative;
  background-image: url(${WalletPhoneBg});
  background-size: cover;
  background-repeat: no-repeat;

  @media screen and (max-width: 850px) {
    height: 600px;
    background-size: contain !important;
  }
`

const PayBoltWallet = styled.div`
  position: absolute;
  bottom: 50px;
  display: flex;
  width: 100%;
  justify-content: space-around;

  @media screen and (max-width: 850px) {
    display: block;
  }
`

const PayBoltWalletText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: black;

  @media screen and (max-width: 850px) {
    font-size: 42px;
    text-align: center;
  }
`

const PayBoltWalletBtn = styled.div`
  img {
    width: 196px;
    height: 70px;
    margin-right: 18px;
    cursor: pointer;
    animation-duration: 2s;
    animation-name: wallet-animation;
    animation-iteration-count: infinite;
    border-radius: 15px;
  }

  @media screen and (max-width: 650px) {
    display: flex;
    gap: 30px;
    flex-direction: column;
    align-items: center;
  }

  @media screen and (max-width: 850px) {
    margin-top: 36px;
    text-align: center;
  }
`

const Home: React.FC = () => {
  return (
    <>
      <Wrapper>
        <PayBoltTitle>PayBolt Wallet</PayBoltTitle>
        <PayBoltSubtitle>
          Secure crypto payments are lightning fast. The more you pay the more you earn!
        </PayBoltSubtitle>
        <PayBoltImage>
          <PayBoltWallet>
            <PayBoltWalletText>Coming soon</PayBoltWalletText>
            <PayBoltWalletBtn>
              <img src={AppStoreComing} alt="AppStore Coming Soon" />
              <img src={GooglePlayComing} alt="Google Play Coming Soon" />
            </PayBoltWalletBtn>
          </PayBoltWallet>
        </PayBoltImage>
        <img src={Border} alt="border" />
      </Wrapper>
    </>
  )
}

export default Home
