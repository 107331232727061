import { SupportedChainId } from './chains'

export const DEFAULT_SELECTED_INPUT = '0xe580074A10360404AF3ABfe2d524D5806D993ea3'

export const INIT_CODE_HASH = '0x96e8ac4277198ff8b6f785478aa9a39f403cb768dd02cbee326c3e7da348845f'

export const INIT_CODE_HASH_MAP = {
  [SupportedChainId.MAINNET]: INIT_CODE_HASH,
  [SupportedChainId.POLYGON]: INIT_CODE_HASH,
  [SupportedChainId.BSC]: '0x00fb7f630766e6a796048ea87d01acd3068e8ff67d078148a3fa3f4a84f69bd5',
  //[SupportedChainId.ROPSTEN]: INIT_CODE_HASH,
  //[SupportedChainId.RINKEBY]: INIT_CODE_HASH,
  //[SupportedChainId.GOERLI]: INIT_CODE_HASH,
  //[SupportedChainId.KOVAN]: INIT_CODE_HASH,
  //[SupportedChainId.POLYGON_MUMBAI]: INIT_CODE_HASH,
  //[SupportedChainId.ARBITRUM_ONE]: INIT_CODE_HASH,
  //[SupportedChainId.ARBITRUM_RINKEBY]: INIT_CODE_HASH,
  //[SupportedChainId.OPTIMISM]: INIT_CODE_HASH,
  //[SupportedChainId.OPTIMISTIC_KOVAN]: INIT_CODE_HASH,
  //[SupportedChainId.BSC_TESTNET]: '0xd0d4c4cd0848c93cb4fd1f498d7013ee6bfb25783ea21593d5834f5d250ece66',
}
