import { Currency } from '@uniswap/sdk-core'
import { PAYBOLT_ADDRESS } from 'constants/addresses'
import { SupportedChainId } from 'constants/chains'
import { WRAPPED_NATIVE_CURRENCY } from 'constants/tokens'
import useHttpLocations from 'hooks/useHttpLocations'
import { useMemo } from 'react'
import { WrappedTokenInfo } from 'state/lists/wrappedTokenInfo'

import EthereumLogo from '../../assets/images/ethereum-logo.png'
import BNBLogo from '../../assets/svg/bnb-token-icon.svg'
import MaticLogo from '../../assets/svg/matic-token-icon.svg'
import PayBoltTokenLogo from '../../assets/svg/paybolt-token-icon.svg'

type Network = 'ethereum' | 'arbitrum' | 'optimism'

function chainIdToNetworkName(networkId: SupportedChainId): Network {
  switch (networkId) {
    case SupportedChainId.MAINNET:
      return 'ethereum'
    //case SupportedChainId.ARBITRUM_ONE:
    //  return 'arbitrum'
    //case SupportedChainId.OPTIMISM:
    //  return 'optimism'
    default:
      return 'ethereum'
  }
}

function getNativeLogoURI(chainId: SupportedChainId = SupportedChainId.MAINNET): string {
  switch (chainId) {
    //case SupportedChainId.POLYGON_MUMBAI:
    case SupportedChainId.POLYGON:
      return MaticLogo
    case SupportedChainId.BSC:
      //case SupportedChainId.BSC_TESTNET:
      return BNBLogo
    default:
      return EthereumLogo
  }
}

function getTokenLogoURI(address: string, chainId: SupportedChainId = SupportedChainId.MAINNET): string | void {
  const networkName = chainIdToNetworkName(chainId)
  const networksWithUrls = [SupportedChainId.MAINNET]
  if (address === PAYBOLT_ADDRESS[chainId]) {
    return PayBoltTokenLogo
  }
  if (chainId === SupportedChainId.BSC && address === WRAPPED_NATIVE_CURRENCY[chainId]?.address) {
    return BNBLogo
  }
  if (networksWithUrls.includes(chainId)) {
    return `https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/${networkName}/assets/${address}/logo.png`
  } else if (chainId === SupportedChainId.BSC) {
    return `https://assets.trustwalletapp.com/blockchains/smartchain/assets/${address}/logo.png`
  }
}

export default function useCurrencyLogoURIs(currency?: Currency | null): string[] {
  const locations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)
  return useMemo(() => {
    const logoURIs = [...locations]
    if (currency) {
      if (currency.isNative) {
        logoURIs.push(getNativeLogoURI(currency.chainId))
      } else if (currency.isToken) {
        const logoURI = getTokenLogoURI(currency.address, currency.chainId)
        if (logoURI) {
          logoURIs.push(logoURI)
        }
      }
    }
    return logoURIs
  }, [currency, locations])
}
