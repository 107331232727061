import styled from 'styled-components/macro'

const InputPanel = styled.div<{ hideInput?: boolean }>`
  ${({ theme }) => theme.flexColumnNoWrap}
  position: relative;
  border-radius: ${({ hideInput }) => (hideInput ? '16px' : '20px')};
  background-color: ${({ theme, hideInput }) => (hideInput ? 'transparent' : theme.bg2)};
  z-index: 1;
  width: ${({ hideInput }) => (hideInput ? '100%' : 'initial')};
  transition: height 1s ease;
  will-change: height;
`

const Container = styled.div`
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.bg0};
  background-color: ${({ theme }) => theme.bg1};
  width: initial;
  :focus,
  :hover {
    border: 1px solid theme.bg3;
  }
`

const InputRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem 1rem 1rem;
`

const StyledInput = styled.input`
  text-align: left;
  color: ${({ theme }) => theme.text1};
  width: 0;
  position: relative;
  font-weight: 500;
  outline: none;
  border: none;
  flex: 1 1 auto;
  background-color: ${({ theme }) => theme.bg1};
  font-size: 26px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px;
  -webkit-appearance: textfield;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  [type='number'] {
    -moz-appearance: textfield;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.text4};
  }
`

const StyledCurrency = styled.div`
  margin: 0 0.25rem 0 0.25rem;
  font-size: 18px;
  font-weight: bold;
`

interface CurrencyInputPanelProps {
  value: string
  currency: string
  id: string
}

export default function CurrencyOutputPanel({ value, currency, id, ...rest }: CurrencyInputPanelProps) {
  return (
    <InputPanel id={id} {...rest}>
      <Container>
        <InputRow>
          <StyledInput className="token-amount-input" value={value} disabled={true} />

          <StyledCurrency>{currency}</StyledCurrency>
        </InputRow>
      </Container>
    </InputPanel>
  )
}
