import { getCreate2Address } from '@ethersproject/address'
import { keccak256, pack } from '@ethersproject/solidity'
import { Token } from '@uniswap/sdk-core'
import { SupportedChainId } from 'constants/chains'
import { INIT_CODE_HASH_MAP } from 'constants/index'

export function computePairAddress({
  factoryAddress,
  tokenA,
  tokenB,
}: {
  factoryAddress: string
  tokenA: Token
  tokenB: Token
}): string {
  const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA] // does safety checks

  const supportedChainId: SupportedChainId | undefined =
    SupportedChainId[SupportedChainId[token0.chainId] as keyof typeof SupportedChainId]

  return getCreate2Address(
    factoryAddress,
    keccak256(['bytes'], [pack(['address', 'address'], [token0.address, token1.address])]),
    INIT_CODE_HASH_MAP[supportedChainId]
  )
}
