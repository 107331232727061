import { Currency, CurrencyAmount, TradeType } from '@uniswap/sdk-core'
import { useMemo } from 'react'
import { InterfaceTrade, TradeState } from 'state/routing/types'

import { useBestV2Trade } from './useBestV2Trade'
import useDebounce from './useDebounce'

/**
 * Returns the best v2+v3 trade for a desired swap.
 * @param tradeType whether the swap is an exact in/out
 * @param amountSpecified the exact amount to swap in/out
 * @param otherCurrency the desired output/payment currency
 */
export function usePayBoltBestTrade(
  tradeType: TradeType,
  amountSpecified?: CurrencyAmount<Currency>,
  otherCurrency?: Currency
): {
  state: TradeState
  trade: InterfaceTrade<Currency, Currency, TradeType> | undefined
} {
  /*
  const { chainId } = useActiveWeb3React()
  const autoRouterSupported = useAutoRouterSupported()
  const isWindowVisible = useIsWindowVisible()

  const [debouncedAmount, debouncedOtherCurrency] = useDebounce(
    useMemo(() => [amountSpecified, otherCurrency], [amountSpecified, otherCurrency]),
    200
  )

  const routingAPITrade = useRoutingAPITrade(
    tradeType,
    autoRouterSupported && isWindowVisible ? debouncedAmount : undefined,
    debouncedOtherCurrency
  )

  const isLoading = routingAPITrade.state === TradeState.LOADING
  const useFallback = chainId === SupportedChainId.BSC

  const bestV2Trade = usePayBoltBestV2Trade(tradeType, debouncedAmount, debouncedOtherCurrency)

  // only return gas estimate from api if routing api trade is used
  return useMemo(
    () => ({
      ...(useFallback ? bestV2Trade : routingAPITrade),
      ...(isLoading ? { state: TradeState.LOADING } : {}),
    }),
    [bestV2Trade, isLoading, routingAPITrade, useFallback]
  )
  */

  const [debouncedAmount, debouncedOtherCurrency] = useDebounce(
    useMemo(() => [amountSpecified, otherCurrency], [amountSpecified, otherCurrency]),
    200
  )

  const bestV2Trade = usePayBoltBestV2Trade(tradeType, debouncedAmount, debouncedOtherCurrency)

  // only return gas estimate from api if routing api trade is used
  return useMemo(
    () => ({
      ...bestV2Trade,
    }),
    [bestV2Trade]
  )
}

export function usePayBoltBestV2Trade<TTradeType extends TradeType>(
  tradeType: TTradeType,
  amountSpecified?: CurrencyAmount<Currency>,
  otherCurrency?: Currency
): { state: TradeState; trade: InterfaceTrade<Currency, Currency, TTradeType> | undefined } {
  const bestV2Trade = useBestV2Trade(tradeType, amountSpecified, otherCurrency)

  return useMemo(() => {
    if (!bestV2Trade) {
      return {
        state: TradeState.NO_ROUTE_FOUND,
        trade: undefined,
      }
    }

    return {
      state: TradeState.VALID,
      trade: new InterfaceTrade({
        v2Routes: [
          {
            routev2: bestV2Trade.route,
            inputAmount: bestV2Trade.inputAmount,
            outputAmount: bestV2Trade.outputAmount,
          },
        ],
        v3Routes: [],
        tradeType,
      }),
    }
  }, [bestV2Trade, tradeType])
}
