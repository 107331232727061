import { USDC_MAINNET, USDC_POLYGON } from '@uniswap/smart-order-router'
import { FACTORY_ADDRESS as V2_FACTORY_ADDRESS } from '@uniswap/v2-sdk'
import { FACTORY_ADDRESS as V3_FACTORY_ADDRESS } from '@uniswap/v3-sdk'

import { constructSameAddressMap } from '../utils/constructSameAddressMap'
import { SupportedChainId } from './chains'

type AddressMap = { [chainId: number]: string }

export const PAYBOLT_ADDRESS: AddressMap = constructSameAddressMap('0xe580074A10360404AF3ABfe2d524D5806D993ea3')

export const PAYBOLT_MERCHANT_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x9E451Cc190bFF64bA91AbC028a8a4FE82A4f0B58',
  //[SupportedChainId.ROPSTEN]: '',
  //[SupportedChainId.RINKEBY]: '',
  //[SupportedChainId.GOERLI]: '',
  //[SupportedChainId.KOVAN]: '',
  [SupportedChainId.BSC]: '0xEf2419D23C0dC9fB906A15C6211Ab25b55F33015',
  [SupportedChainId.POLYGON]: '0x4c1A71DD2754Ac5E8B9e20bea385B3752D2B80Cd',
}

export const PAYBOLT_EXTENSION_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x080C99Cbe5A0DC97dEE59BE0aF6c6E23CDC9344e',
  //[SupportedChainId.ROPSTEN]: '',
  //[SupportedChainId.RINKEBY]: '',
  //[SupportedChainId.GOERLI]: '',
  //[SupportedChainId.KOVAN]: '',
  [SupportedChainId.BSC]: '0x2786967e552aabe8D391d9752620f4FcbCbf326A',
  [SupportedChainId.POLYGON]: '0x0720Bed2bE77cA31F3CA4272305a3709C6F0aFB8',
}

export const USD_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: USDC_MAINNET.address,
  //[SupportedChainId.ROPSTEN]: USDC_ROPSTEN.address,
  //[SupportedChainId.RINKEBY]: USDC_RINKEBY.address,
  //[SupportedChainId.GOERLI]: USDC_GÖRLI.address,
  //[SupportedChainId.KOVAN]: USDC_KOVAN.address,
  [SupportedChainId.BSC]: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', // BUSD
  [SupportedChainId.POLYGON]: USDC_POLYGON.address,
}

export const UNI_ADDRESS: AddressMap = constructSameAddressMap('0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984')
export const MULTICALL_ADDRESS: AddressMap = {
  //...constructSameAddressMap('0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696', [
  //  SupportedChainId.OPTIMISTIC_KOVAN,
  //  SupportedChainId.OPTIMISM,
  //  SupportedChainId.POLYGON_MUMBAI,
  //  //SupportedChainId.POLYGON,
  //]),
  //[SupportedChainId.ARBITRUM_ONE]: '0xadF885960B47eA2CD9B55E6DAc6B42b7Cb2806dB',
  //[SupportedChainId.ARBITRUM_RINKEBY]: '0xa501c031958F579dB7676fF1CE78AD305794d579',
  [SupportedChainId.MAINNET]: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
  [SupportedChainId.BSC]: '0xc725266C079339B088b4BA9139b86d7d4A64CB8d',
  [SupportedChainId.POLYGON]: '0x02817C1e3543c2d908a590F5dB6bc97f933dB4BD',
}
export const V2_FACTORY_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: V2_FACTORY_ADDRESS,
  //[SupportedChainId.ROPSTEN]: V2_FACTORY_ADDRESS,
  //[SupportedChainId.RINKEBY]: V2_FACTORY_ADDRESS,
  //[SupportedChainId.GOERLI]: V2_FACTORY_ADDRESS,
  //[SupportedChainId.KOVAN]: V2_FACTORY_ADDRESS,
  [SupportedChainId.BSC]: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73',
  [SupportedChainId.POLYGON]: '0x5757371414417b8C6CAad45bAeF941aBc7d3Ab32',
}

export const V2_ROUTER_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
  //[SupportedChainId.ROPSTEN]: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
  //[SupportedChainId.RINKEBY]: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
  //[SupportedChainId.GOERLI]: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
  //[SupportedChainId.KOVAN]: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
  [SupportedChainId.BSC]: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
  [SupportedChainId.POLYGON]: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff',
}

export const V3_ROUTER_ADDRESS: AddressMap = constructSameAddressMap('0xE592427A0AEce92De3Edee1F18E0157C05861564', [
  //SupportedChainId.OPTIMISM,
  //SupportedChainId.OPTIMISTIC_KOVAN,
  //SupportedChainId.ARBITRUM_ONE,
  //SupportedChainId.ARBITRUM_RINKEBY,
  SupportedChainId.POLYGON,
  //SupportedChainId.POLYGON_MUMBAI,
])
export const SWAP_ROUTER_ADDRESSES: AddressMap = constructSameAddressMap('0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45', [
  //SupportedChainId.OPTIMISM,
  //SupportedChainId.OPTIMISTIC_KOVAN,
  //SupportedChainId.ARBITRUM_ONE,
  //SupportedChainId.ARBITRUM_RINKEBY,
  SupportedChainId.POLYGON,
  //SupportedChainId.POLYGON_MUMBAI,
])

/**
 * The oldest V0 governance address
 */
export const GOVERNANCE_ALPHA_V0_ADDRESSES: AddressMap = constructSameAddressMap(
  '0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F'
)
/**
 * The older V1 governance address
 */
export const GOVERNANCE_ALPHA_V1_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0xC4e172459f1E7939D522503B81AFAaC1014CE6F6',
}
/**
 * The latest governor bravo that is currently admin of timelock
 */
export const GOVERNANCE_BRAVO_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x408ED6354d4973f66138C91495F2f2FCbd8724C3',
}

export const TIMELOCK_ADDRESS: AddressMap = constructSameAddressMap('0x1a9C8182C09F50C8318d769245beA52c32BE35BC')

export const MERKLE_DISTRIBUTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x090D4613473dEE047c3f2706764f49E0821D256e',
}
export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0xeca4B0bDBf7c55E9b7925919d03CbF8Dc82537E8',
}
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = constructSameAddressMap(V3_FACTORY_ADDRESS, [
  //SupportedChainId.OPTIMISM,
  //SupportedChainId.OPTIMISTIC_KOVAN,
  //SupportedChainId.ARBITRUM_ONE,
  //SupportedChainId.ARBITRUM_RINKEBY,
  //SupportedChainId.POLYGON_MUMBAI,
  SupportedChainId.POLYGON,
])
export const QUOTER_ADDRESSES: AddressMap = constructSameAddressMap('0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6', [
  //SupportedChainId.OPTIMISM,
  //SupportedChainId.OPTIMISTIC_KOVAN,
  //SupportedChainId.ARBITRUM_ONE,
  //SupportedChainId.ARBITRUM_RINKEBY,
  //SupportedChainId.POLYGON_MUMBAI,
  SupportedChainId.POLYGON,
])
export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = constructSameAddressMap(
  '0xC36442b4a4522E871399CD717aBDD847Ab11FE88',
  [
    //SupportedChainId.OPTIMISM,
    //SupportedChainId.OPTIMISTIC_KOVAN,
    //SupportedChainId.ARBITRUM_ONE,
    //SupportedChainId.ARBITRUM_RINKEBY,
    //SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]
)
export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  //[SupportedChainId.ROPSTEN]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  //[SupportedChainId.GOERLI]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  //[SupportedChainId.RINKEBY]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
}
export const SOCKS_CONTROLLER_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x65770b5283117639760beA3F867b69b3697a91dd',
}

export const V3_MIGRATOR_ADDRESSES: AddressMap = constructSameAddressMap('0xA5644E29708357803b5A882D272c41cC0dF92B34', [
  //SupportedChainId.ARBITRUM_ONE,
  //SupportedChainId.ARBITRUM_RINKEBY,
  //SupportedChainId.POLYGON_MUMBAI,
  SupportedChainId.POLYGON,
])

export const TICK_LENS_ADDRESSES: AddressMap = {
  //[SupportedChainId.ARBITRUM_ONE]: '0xbfd8137f7d1516D3ea5cA83523914859ec47F573',
  //[SupportedChainId.ARBITRUM_RINKEBY]: '0xbfd8137f7d1516D3ea5cA83523914859ec47F573',
}
