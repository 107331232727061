export enum ChainId {
  MAINNET = 1,
  //ROPSTEN = 3,
  //RINKEBY = 4,
  //GÖRLI = 5,
  //KOVAN = 42,
  //OPTIMISM = 10,
  //OPTIMISTIC_KOVAN = 69,
  //ARBITRUM_ONE = 42161,
  //ARBITRUM_RINKEBY = 421611,
  POLYGON = 137,
  MATIC = 137,
  //POLYGON_MUMBAI = 80001,
  //MATIC_MUMBAI = 80001,
  BSC = 56,
  //BSC_TESTNET = 97,
}

/**
 * List of all the networks supported by the Uniswap Interface
 */
export enum SupportedChainId {
  MAINNET = 1,
  //ROPSTEN = 3,
  //RINKEBY = 4,
  //GOERLI = 5,
  //KOVAN = 42,

  //ARBITRUM_ONE = 42161,
  //ARBITRUM_RINKEBY = 421611,

  //OPTIMISM = 10,
  //OPTIMISTIC_KOVAN = 69,

  POLYGON = 137,
  //POLYGON_MUMBAI = 80001,

  BSC = 56,
  //BSC_TESTNET = 97,
}

export const CHAIN_IDS_TO_NAMES = {
  [SupportedChainId.MAINNET]: 'mainnet',
  //[SupportedChainId.ROPSTEN]: 'ropsten',
  //[SupportedChainId.RINKEBY]: 'rinkeby',
  //[SupportedChainId.GOERLI]: 'goerli',
  //[SupportedChainId.KOVAN]: 'kovan',
  [SupportedChainId.POLYGON]: 'polygon',
  //[SupportedChainId.POLYGON_MUMBAI]: 'polygon_mumbai',
  //[SupportedChainId.ARBITRUM_ONE]: 'arbitrum',
  //[SupportedChainId.ARBITRUM_RINKEBY]: 'arbitrum_rinkeby',
  //[SupportedChainId.OPTIMISM]: 'optimism',
  //[SupportedChainId.OPTIMISTIC_KOVAN]: 'optimistic_kovan',
  [SupportedChainId.BSC]: 'bsc',
  //[SupportedChainId.BSC_TESTNET]: 'bsc_testnet',
}

/**
 * Array of all the supported chain IDs
 */
export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = Object.values(SupportedChainId).filter(
  (id) => typeof id === 'number'
) as SupportedChainId[]

export const SUPPORTED_GAS_ESTIMATE_CHAIN_IDS = [
  SupportedChainId.MAINNET,
  SupportedChainId.POLYGON,
  //SupportedChainId.OPTIMISM,
  //SupportedChainId.ARBITRUM_ONE,
  SupportedChainId.BSC,
]

/**
 * All the chain IDs that are running the Ethereum protocol.
 */
export const L1_CHAIN_IDS = [
  SupportedChainId.MAINNET,
  //SupportedChainId.ROPSTEN,
  //SupportedChainId.RINKEBY,
  //SupportedChainId.GOERLI,
  //SupportedChainId.KOVAN,
  SupportedChainId.POLYGON,
  //SupportedChainId.POLYGON_MUMBAI,
] as const

export type SupportedL1ChainId = typeof L1_CHAIN_IDS[number]

/**
 * Controls some L2 specific behavior, e.g. slippage tolerance, special UI behavior.
 * The expectation is that all of these networks have immediate transaction confirmation.
 */
export const L2_CHAIN_IDS = [
  //SupportedChainId.ARBITRUM_ONE,
  //SupportedChainId.ARBITRUM_RINKEBY,
  //SupportedChainId.OPTIMISM,
  //SupportedChainId.OPTIMISTIC_KOVAN,
] as const

export type SupportedL2ChainId = typeof L2_CHAIN_IDS[number]
